import { TriangleAlertIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { usePermission } from 'react-use';
import { match } from 'ts-pattern';

import {
  Box,
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@eluve/components';

type MicrophoneOption = {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
};

interface MicrophoneDeviceSelectorProps {
  onDeviceValueChange?: (deviceId: string) => void;
  selectedMicId?: string | null;
  setHasPreviouslySelectedMicId?: (hasPreviouslySelectedMicId: boolean) => void;
}

export const MicrophoneDeviceSelector: React.FC<
  MicrophoneDeviceSelectorProps
> = ({ onDeviceValueChange, selectedMicId, setHasPreviouslySelectedMicId }) => {
  const state = usePermission({ name: 'microphone' });
  const [microphoneOptions, setMicrophoneOptions] = useState<
    MicrophoneOption[]
  >([]);

  const loadMicrophoneOptions = useCallback(async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioDevices = devices.filter(
      (device) => device.kind === 'audioinput' && device.deviceId,
    );
    setMicrophoneOptions(audioDevices as MicrophoneOption[]);
  }, []);

  useEffect(() => {
    navigator.mediaDevices.addEventListener(
      'devicechange',
      loadMicrophoneOptions,
    );
  }, [loadMicrophoneOptions]);

  useEffect(() => {
    if (state === 'granted') {
      loadMicrophoneOptions();
    }
  }, [loadMicrophoneOptions, state]);

  useEffect(() => {
    if (selectedMicId && setHasPreviouslySelectedMicId) {
      setHasPreviouslySelectedMicId(
        Boolean(
          microphoneOptions.find((mic) => mic.deviceId === selectedMicId),
        ),
      );
    }
  }, [microphoneOptions, selectedMicId, setHasPreviouslySelectedMicId]);

  const selectedMic =
    microphoneOptions.find((mic) => mic.deviceId === selectedMicId)?.label ??
    'Select Microphone';

  const handleMicChange = (deviceId: string) => {
    onDeviceValueChange?.(deviceId);
  };

  const requestMicrophoneAccess = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((device) => {
      device.getTracks().forEach((track) => {
        track.stop();
      });
    });
  };

  return match({ state, microphoneOptions })
    .with({ state: 'prompt' }, () => (
      <Box vStack center fullWidth className="bg-amber-100 p-4 text-amber-600">
        <TriangleAlertIcon size={24} />
        Microphone permissions required
        <span className="text-xs">
          Please grant microphone permissions to enable audio input.
        </span>
        <Button
          onClick={requestMicrophoneAccess}
          variant="gray"
          size="sm"
          className="cursor-pointer"
        >
          Request Permission
        </Button>
      </Box>
    ))
    .with({ state: 'denied' }, () => (
      <Box vStack center fullWidth className="bg-red-100 p-4 text-red-600">
        <TriangleAlertIcon size={24} />
        Microphone permissions denied
        <span className="text-xs">
          Please enable microphone permissions in your browser settings to
          enable audio input.
        </span>
      </Box>
    ))
    .with({ microphoneOptions: [] }, () => (
      <Box vStack center fullWidth className="bg-amber-100 p-4 text-amber-600">
        <TriangleAlertIcon size={24} />
        No microphone found
        <span className="text-xs">
          Please ensure your microphone is connected and check its permissions
          in your browser settings to enable audio input.
        </span>
      </Box>
    ))
    .otherwise(() => (
      <Select
        onValueChange={handleMicChange}
        onOpenChange={loadMicrophoneOptions}
      >
        <SelectTrigger className="text-gray-12">
          <SelectValue className="text-gray-12" placeholder={selectedMic} />
        </SelectTrigger>
        <SelectContent>
          {microphoneOptions.map((device) => {
            return (
              <SelectItem
                key={device.label}
                className="focus:bg-brand-4 pl-7"
                value={device.deviceId}
              >
                {device.label}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
    ));
};
