import { ApolloClient } from '@apollo/client';
import pRetry from 'p-retry';
import { v4 } from 'uuid';
import { fromCallback } from 'xstate';

import { TsRestClient } from '@eluve/api-client-provider';
import { usePreventAutoReloadOnNewVersion } from '@eluve/blocks';
import { appointmentTotalDurationFragment } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';
import { Logger } from '@eluve/logger';
import { calculateFileMd5 } from '@eluve/utils';

import { DexieDb } from '../files-db';
import { getAudioDurationFromFile } from '../getAudioDurationFromFile';
import { SupportedAudioFormat } from '../getSupportedAudioFormat';

const getAppointmentTotalDuration = graphql(
  `
    query getAppointmentTotalDuration($appointmentId: uuid!, $tenantId: uuid!) {
      appointmentsByPk(id: $appointmentId, tenantId: $tenantId) {
        __typename
        id
        ...appointmentTotalDuration
      }
    }
  `,
  [appointmentTotalDurationFragment],
);

export type Input = {
  apiClient: TsRestClient;
  tenantId: string;
  appointmentId: string;
  file: File;
  format: SupportedAudioFormat;
  shouldBeTranscribed: boolean;
  wasDegraded: boolean;
  recordingStartedAt: string;
  isBackgroundUpload: boolean;
  db: DexieDb;
  logger: Logger;
  apolloClient: ApolloClient<unknown>;
};

export type UploadFileEvents =
  | {
      type: 'UPLOAD_FILE.COMPLETED';
      fileURL: string;
    }
  | {
      type: 'UPLOAD_FILE.FAILED';
      error: Error;
    }
  | {
      type: 'UPLOAD_FILE.UPLOADING';
      progress: number;
    };

const safeGetAudioDurationFromFile = async (file: File, logger: Logger) => {
  let recordingDuration: number | null = null;
  try {
    const startTime = performance.now();
    recordingDuration = await getAudioDurationFromFile(file);
    logger.info('Calculating audio duration from file completed', {
      time: performance.now() - startTime,
    });
  } catch (error) {
    logger.warn('Failed to get audio duration from file', {
      error,
    });
  }
  return recordingDuration;
};

const safeCalculateFileHash = async (file: File, logger: Logger) => {
  let fileHash = '';
  try {
    const startTime = performance.now();
    fileHash = await calculateFileMd5(file);
    logger.info('Calculating file hash', {
      time: performance.now() - startTime,
    });
  } catch (error) {
    logger.warn('Failed to calculate hash', {
      error,
    });
  }
  return fileHash;
};

export const uploadAppointmentFile = fromCallback<UploadFileEvents, Input>(
  ({ input, sendBack }) => {
    const localUploadId = v4();

    const {
      apiClient,
      file,
      format,
      tenantId,
      appointmentId,
      shouldBeTranscribed,
      wasDegraded,
      recordingStartedAt,
      isBackgroundUpload,
      db,
      logger,
      apolloClient,
    } = input;

    (async () => {
      let fileURL = '';
      let fileUploadUrl = '';
      usePreventAutoReloadOnNewVersion.setState(true);

      const [recordingDuration, fileHash] = await Promise.all([
        safeGetAudioDurationFromFile(file, logger),
        safeCalculateFileHash(file, logger),
      ]);

      try {
        await pRetry(
          async () => {
            const generateSignedUrlResponse =
              await apiClient.appointments.prepareAppointmentFileUpload({
                params: {
                  tenantId,
                  appointmentId,
                },
                body: {
                  fileSize: file.size,
                  fileHash,
                  extension: format.extension,
                  fileType: 'USER_BACKUP_AUDIO',
                  uploadId: localUploadId,
                  fileName: file.name,
                },
              });

            if (generateSignedUrlResponse.status !== 201) {
              throw new Error('Failed to generate signed url');
            }

            const { gcsFilePath, uploadURL } = generateSignedUrlResponse.body;
            fileURL = gcsFilePath;
            fileUploadUrl = uploadURL;
          },
          {
            // Allow retry up to 3 times with delay to address intermittent client network issues
            retries: 3,
            onFailedAttempt: () =>
              new Promise((resolve) => setTimeout(resolve, 5000)),
          },
        );
      } catch (error) {
        sendBack({
          type: 'UPLOAD_FILE.FAILED',
          error: new Error('Failed to upload file'),
        });

        usePreventAutoReloadOnNewVersion.setState(false);
        return;
      }

      try {
        await pRetry(
          async () => {
            await new Promise((resolve, reject) => {
              const xhr = new XMLHttpRequest();

              sendBack({ type: 'UPLOAD_FILE.UPLOADING', progress: 0 });
              xhr.open('PUT', fileUploadUrl, true);
              xhr.setRequestHeader('Content-Type', format.mimeType);

              xhr.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                  const progress = Math.round(
                    (event.loaded / event.total) * 100,
                  );
                  sendBack({ type: 'UPLOAD_FILE.UPLOADING', progress });
                }
              });

              xhr.onload = () => {
                if (xhr.status === 200) {
                  resolve(xhr.response);
                } else {
                  reject(new Error('Failed to upload file'));
                }
              };

              xhr.onerror = () => {
                reject(new Error('Failed to upload file'));
              };

              xhr.send(file);
            });
          },
          {
            retries: 3,
            onFailedAttempt: () =>
              new Promise((resolve) => setTimeout(resolve, 5000)),
          },
        );
      } catch (error) {
        sendBack({
          type: 'UPLOAD_FILE.FAILED',
          error: new Error('Failed to upload file'),
        });

        usePreventAutoReloadOnNewVersion.setState(false);

        return;
      }

      const markFileCompleteResponse =
        await apiClient.appointments.markFileUploadComplete({
          params: {
            tenantId,
            appointmentId,
          },
          body: {
            // Remove the file extension here to get the recording started at
            // in order to correlate the segment on the backend
            recordingDuration,
            recordingStartedAt,
            uploadId: localUploadId,
            shouldBeTranscribed,
            wasSegmentDegraded: wasDegraded,
          },
        });

      if (markFileCompleteResponse.status !== 200) {
        sendBack({
          type: 'UPLOAD_FILE.FAILED',
          error: new Error('Failed to mark file upload as complete'),
        });
      } else {
        logger.info(
          'File upload completed. Removing reference from IndexedDB',
          {
            fileName: file.name,
            appointmentId,
          },
        );
        await db.appointmentFiles.delete(file.name);

        void apolloClient.query({
          query: getAppointmentTotalDuration,
          variables: {
            appointmentId,
            tenantId,
          },
        });

        sendBack({ type: 'UPLOAD_FILE.COMPLETED', fileURL });
      }

      usePreventAutoReloadOnNewVersion.setState(false);
    })();
  },
);
