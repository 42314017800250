import { useMutation, useQuery, useSubscription } from '@apollo/client';
import noop from 'lodash/noop';
import { Check, LogOut, Star } from 'lucide-react';
import React, { useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { match } from 'ts-pattern';

import { useApiClient } from '@eluve/api-client-provider';
import {
  Button,
  HStack,
  P,
  Progress,
  Separator,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  VStack,
  labelTextStyles,
} from '@eluve/components';
import {
  allCompletedSoapNotesFragment,
  listenAppointmentStatusSubscription,
  updateSoapNote,
  useAllSummaries,
  useAppointmentActivity,
  useIsAppointmentSigned,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';
import { useUserIdFromSession } from '@eluve/session-helpers';
import {
  DynamicArtifactRenderer,
  SummaryFeedbackModal,
  SummarySectionMarkdown,
} from '@eluve/smart-blocks';
import { AppointmentSummary, AppointmentSummaryKeys } from '@eluve/utils';

import { useListenForAnySummaries } from './useListenForAnySummaries';

export const getPromptTemplates = graphql(`
  query getPromptTemplates($tenantId: uuid!, $userId: uuid!) {
    activePromptTemplates(orderBy: { name: ASC }) {
      __typename
      id
      description
      name
      isCurrentDefault
    }
    tenantUserSettingsByPk(tenantId: $tenantId, userId: $userId) {
      __typename
      tenantId
      userId
      promptTemplateIdOrDefault
    }
  }
`);

const listenAllSoapNotes = graphql(
  `
    subscription listenAllSoapNotes($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        ...AllCompletedSoapNotes
      }
    }
  `,
  [allCompletedSoapNotesFragment],
);

export const SummaryComparison: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedSummaryId = searchParams.get('summaryId') ?? undefined;
  const [progress, setProgress] = useState(0);
  const userId = useUserIdFromSession();

  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  const isNoteSigned = useIsAppointmentSigned();

  const [selectedPromptTemplates, setSelectedPromptTemplates] = useState<
    string[]
  >([]);

  const { data: promptTemplatesAndSettings } = useQuery(getPromptTemplates, {
    variables: { tenantId, userId },
  });

  const apiClient = useApiClient();

  const { isAnySummarizationInProgress } = useAppointmentActivity();

  const currentSummary = useSummary();

  const [updateSummary] = useMutation(updateSoapNote, {
    optimisticResponse: (data) => {
      return {
        updateHumanOutputsByPk: {
          __typename: 'HumanOutputs' as const,
          id: data.humanOutputId,
          content: data.content,
          editedFromLlmOutputId: data.llmOutputId,
        },
      };
    },
  });

  const generateSummaries = async () => {
    setProgress(0);
    const internvalId = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(internvalId);
        }
        return prev + 1;
      });
    }, 750);

    if (
      !promptTemplatesAndSettings?.activePromptTemplates?.length ||
      !selectedPromptTemplates.length
    ) {
      return;
    }

    await apiClient.llm.summarizeAppointmentWithMultipleTemplates({
      body: {
        promptTemplateIds: selectedPromptTemplates,
      },
      params: {
        tenantId,
        appointmentId,
      },
    });

    setSelectedPromptTemplates([]);
  };

  const showFeedback = true;

  useListenForAnySummaries();

  useSubscription(listenAppointmentStatusSubscription, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  useSubscription(listenAllSoapNotes, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  const { summaries } = useAllSummaries({ soapNotesOnly: false });

  const filteredSummaries = (summaries ?? [])
    .filter((s) => s.isSummaryAvailable)
    .sort((a, b) => {
      if (!a.summarizationCompletedAt || !b.summarizationCompletedAt) {
        // if value is undefined, push to end of list
        return -1;
      }
      return (
        new Date(a.summarizationCompletedAt).valueOf() -
        new Date(b.summarizationCompletedAt).valueOf()
      );
    });

  return (
    <VStack gap={6} className="container my-6">
      <Link to={`/tenants/${tenantId}/appointments/${appointmentId}`}>
        <Button variant="outline">
          Return to your session <LogOut className="ml-2" />
        </Button>
      </Link>
      <VStack gap={6}>
        <div className="b-1 grid w-full grid-flow-col grid-cols-[repeat(auto-fill,200px)] grid-rows-[auto_minmax(auto,120px)_auto] gap-2 overflow-x-scroll pb-1">
          {Boolean(promptTemplatesAndSettings?.activePromptTemplates?.length) &&
            promptTemplatesAndSettings?.activePromptTemplates.map(
              (promptTemplate, index) => {
                const isUserDefaultTemplate =
                  promptTemplatesAndSettings?.tenantUserSettingsByPk
                    ?.promptTemplateIdOrDefault ??
                  promptTemplatesAndSettings?.activePromptTemplates?.find(
                    (template) => template.isCurrentDefault,
                  )?.id;

                return (
                  <div
                    className="row-span-3 grid min-w-40 grid-rows-subgrid rounded-md border shadow-md"
                    key={index}
                  >
                    <HStack className="border-b p-2" align="start">
                      {isUserDefaultTemplate === promptTemplate.id && (
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger className="hover:cursor-default">
                              <Star className="text-brand-8 h-5" />
                            </TooltipTrigger>
                            <TooltipContent>
                              This is set as your default template. You can
                              change it in your settings.
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      )}
                      <P>{promptTemplate.name}</P>
                    </HStack>
                    <P
                      className={labelTextStyles({
                        className: 'overflow-scroll px-2',
                      })}
                    >
                      {promptTemplate.description}
                    </P>
                    <Button
                      variant="gray"
                      className="rounded-none"
                      onClick={() => {
                        setSelectedPromptTemplates((prev) => {
                          if (prev.includes(promptTemplate.id!)) {
                            return prev.filter(
                              (id) => id !== promptTemplate.id,
                            );
                          }
                          return [...prev, promptTemplate.id!];
                        });
                      }}
                    >
                      {selectedPromptTemplates.includes(
                        promptTemplate.id ?? '',
                      ) ? (
                        <Check className="text-brand-8 h-5 text-white" />
                      ) : (
                        'Select'
                      )}
                    </Button>
                  </div>
                );
              },
            )}
        </div>
        <HStack justify="center">
          <Button
            disabled={
              !selectedPromptTemplates.length ||
              Boolean(isAnySummarizationInProgress)
            }
            type="submit"
            variant="gray"
            className="relative w-52 border-none p-0"
            onClick={generateSummaries}
          >
            <div className="absolute flex h-full w-full items-center justify-center">
              <div className="absolute flex h-full w-full items-center justify-center">
                <Progress
                  value={progress}
                  className={`absolute left-0 top-0 h-10 w-full rounded-sm ${isAnySummarizationInProgress ? 'opacity-30' : 'opacity-0'}`}
                />
              </div>
              <div className="absolute flex h-full w-full items-center justify-center">
                {isAnySummarizationInProgress
                  ? 'Generating...'
                  : 'Generate Summaries'}
              </div>
            </div>
          </Button>
        </HStack>

        <Separator />

        <Tabs
          className="w-full"
          value={selectedSummaryId ?? currentSummary?.llmOutputId ?? undefined}
        >
          <TabsList className="flex h-full w-full flex-wrap gap-2 bg-transparent">
            {filteredSummaries.map((llmSummary, index) => {
              const promptTemplateId = llmSummary.promptTemplateId;
              const promptTemplate =
                promptTemplatesAndSettings?.activePromptTemplates?.find(
                  (p) => p.id === promptTemplateId,
                );
              const promptTemplateName =
                promptTemplate?.name ?? `Inactive Template`;
              return (
                <TabsTrigger
                  key={llmSummary.summaryId}
                  value={llmSummary.summaryId}
                  onClick={() => {
                    const urlSearchParams = new URLSearchParams();
                    urlSearchParams.set('summaryId', llmSummary.summaryId);
                    setSearchParams(urlSearchParams, {
                      replace: true,
                    });
                  }}
                  className="bg-gray-4 flex border data-[state=active]:shadow-md"
                >
                  {promptTemplateName} {`(#${index + 1})`}
                  {llmSummary.summaryId === currentSummary?.llmOutputId && (
                    <Check className="text-brand-8 h-5" />
                  )}
                </TabsTrigger>
              );
            })}
          </TabsList>
          {filteredSummaries.map((llmSummary) => {
            const canSetNewSummary =
              !isNoteSigned &&
              llmSummary.summaryId !== currentSummary?.llmOutputId;

            return (
              <TabsContent
                value={llmSummary.summaryId}
                key={llmSummary.summaryId}
              >
                <div className="m-4 flex flex-col items-center">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          type="submit"
                          disabled={!canSetNewSummary}
                          variant="gray"
                          onClick={async () => {
                            await updateSummary({
                              variables: {
                                content:
                                  llmSummary.summary as AppointmentSummary,
                                llmOutputId: llmSummary.summaryId,
                                tenantId,
                                humanOutputId: currentSummary!.humanOutputId!,
                              },
                            });
                          }}
                        >
                          Use this summary
                        </Button>
                      </TooltipTrigger>
                      {!canSetNewSummary && (
                        <TooltipContent>
                          {isNoteSigned && 'This note is already signed'}
                          {!isNoteSigned &&
                            llmSummary.summaryId ===
                              currentSummary?.llmOutputId &&
                            'This note is already set as current summary'}
                        </TooltipContent>
                      )}
                    </Tooltip>
                  </TooltipProvider>

                  <P className="mb-2 mt-2">
                    Template ID: {llmSummary.promptTemplateId}
                  </P>
                </div>
                <VStack>
                  {match(llmSummary.summary)
                    .with({ type: 'SOAP' }, (soapSummary) =>
                      soapSummary.data
                        ? Object.entries(soapSummary.data).map(
                            ([summaryKey, summaryValue]) => {
                              return (
                                <SummarySectionMarkdown
                                  appointmentId={appointmentId}
                                  key={summaryKey}
                                  summaryKey={
                                    summaryKey as AppointmentSummaryKeys
                                  }
                                  sectionTitle={summaryKey}
                                  content={summaryValue ?? ''}
                                  disabled={true}
                                  handleContentChange={noop}
                                />
                              );
                            },
                          )
                        : null,
                    )
                    .otherwise((dynamicSummary) => (
                      <DynamicArtifactRenderer
                        isReadonly={true}
                        blocks={dynamicSummary.blocks}
                      />
                    ))}

                  {showFeedback && (
                    <HStack justify="end">
                      <SummaryFeedbackModal
                        appointmentId={appointmentId}
                        llmOutputId={llmSummary.summaryId}
                      />
                    </HStack>
                  )}
                </VStack>
              </TabsContent>
            );
          })}
        </Tabs>
      </VStack>
    </VStack>
  );
};
