import {
  FCC,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '@eluve/components';

/**
 * A wrapper component for simple tooltip solution that display a text label when hovered over child
 * @param label The label to display in the tooltip.
 * @param children The children component to wrap with a tooltip.
 *
 * behind the scenes,
 * ```tsx
 * <TooltipProvider>
 *   <Tooltip>
 *     <TooltipTrigger asChild>{children}</TooltipTrigger>
 *     <TooltipPortal>
 *       <TooltipContent sideOffset={8}>{label}</TooltipContent>
 *     </TooltipPortal>
 *   </Tooltip>
 *  </TooltipProvider>
 * ```
 */

export const TooltipLabel: FCC<{
  label: React.ReactNode;
  asChild?: boolean;
  showArrow?: boolean;
  tooltipContentProps?: React.ComponentPropsWithoutRef<typeof TooltipContent>;
  tooltipArrowProps?: React.ComponentPropsWithoutRef<typeof TooltipArrow>;
}> = ({
  label,
  children,
  asChild = true,
  showArrow = false,
  tooltipArrowProps,
  tooltipContentProps,
}) => (
  <TooltipProvider>
    <Tooltip delayDuration={100}>
      <TooltipTrigger asChild={asChild}>{children}</TooltipTrigger>
      <TooltipPortal>
        <TooltipContent {...tooltipContentProps}>
          <span className="text-label1">{label}</span>
          {showArrow && <TooltipArrow {...tooltipArrowProps} />}
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  </TooltipProvider>
);
