import { useMutation } from '@apollo/client';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  baseAppointmentInfoFragment,
  updateAppointmentOutputTemplateId,
  updateAppointmentPromptTemplateId,
  useAppointmentContext,
} from '@eluve/frontend-appointment-hooks';
import { usePromptTemplates } from '@eluve/frontend-feature-user-settings';

export const useAppointmentPromptTemplate = () => {
  const { tenantId, appointmentId } = useAppointmentContext();

  const baseAppointmentInfo = useCompleteFragment({
    fragment: baseAppointmentInfoFragment,
    key: { id: appointmentId },
    strict: false,
  });

  const {
    activeSOAPPromptTemplates,
    userSettingsPromptTemplateId,
    defaultPromptTemplateId,
    activeOutputTemplates,
    defaultOutputTemplateId,
  } = usePromptTemplates();

  const selectedPromptTemplate = activeSOAPPromptTemplates?.find(
    (p) =>
      p.id ===
      (baseAppointmentInfo?.initialPromptTemplateId ??
        userSettingsPromptTemplateId ??
        defaultPromptTemplateId),
  );
  const selectedOutputTemplate = activeOutputTemplates?.find(
    (p) =>
      p.id === baseAppointmentInfo?.initialLlmOutputTemplateId ??
      defaultOutputTemplateId,
  );

  const [updateAppointmentPromptTemplateIdMutation] = useMutation(
    updateAppointmentPromptTemplateId,
    {
      optimisticResponse: (data) => ({
        updateAppointmentsByPk: {
          __typename: 'Appointments' as const,
          initialPromptTemplateId: data.initialPromptTemplateId,
          id: appointmentId,
        },
      }),
    },
  );

  const [updateAppointmentOutputTemplateIdMutation] = useMutation(
    updateAppointmentOutputTemplateId,
    {
      optimisticResponse: (data) => ({
        updateAppointmentsByPk: {
          __typename: 'Appointments' as const,
          initialLlmOutputTemplateId: data.initialLlmOutputTemplateId ?? null,
          id: appointmentId,
        },
      }),
    },
  );

  const updateAppointmentPromptTemplate = async (
    initialPromptTemplateId: string,
  ) => {
    await updateAppointmentPromptTemplateIdMutation({
      variables: {
        tenantId,
        appointmentId,
        initialPromptTemplateId,
      },
    });
  };

  const updateAppointmentOutputTemplate = async (
    initialLlmOutputTemplateId: string | null,
  ) => {
    await updateAppointmentOutputTemplateIdMutation({
      variables: {
        tenantId,
        appointmentId,
        initialLlmOutputTemplateId,
      },
    });
  };

  return {
    selectedPromptTemplate,
    updateAppointmentPromptTemplate,
    selectedOutputTemplate,
    updateAppointmentOutputTemplate,
  };
};
