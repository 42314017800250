import { AlertDialogTrigger } from '@radix-ui/react-alert-dialog';
import { useSelector } from '@xstate/store/react';
import {
  AlertCircle,
  ArrowDownToLine,
  ArrowUpToLine,
  FoldVertical,
  Grip,
  Trash,
  UnfoldVertical,
} from 'lucide-react';
import { HTMLAttributes, forwardRef } from 'react';
import { tv } from 'tailwind-variants';
import { match } from 'ts-pattern';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Badge,
  Box,
  Button,
  P,
  Separator,
  labelTextStyles,
  titleTextStyles,
} from '@eluve/components';

import { TooltipLabel } from '../TooltipLabel';

import { BlockDetailsForm } from './BlockDetailsForm';
import { BlockPickerPopover } from './BlockPicker';
import { BlockTypeIcon } from './BlockTypeIcon';
import { GroupBlockControl } from './blocks/GroupBlockControl';
import { templateBuilderStore } from './templateBuilderStore';
import { FlattenedBlock } from './utilities';

const treeItemStyles = tv({
  slots: {
    wrapper: 'w-full list-none',
    treeItem: 'rounded-sm border px-3 py-3 shadow-sm',
    blockStyles: '',
    iconBadgeStyles: 'gap-2',
  },
  variants: {
    invalid: {
      true: {
        iconBadgeStyles: 'border-red-500 text-red-500',
      },
    },
    collapsed: {
      true: {
        blockStyles: 'hidden',
      },
    },
    ghost: {
      true: {
        treeItem: 'opacity-50 [&>*]:bg-transparent',
      },
    },
    clone: {
      true: {
        wrapper: 'pointer-events-none inline-block pt-2',
        treeItem: 'rounded-lg py-2 pr-6 shadow-lg',
      },
    },
  },
});

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  value: FlattenedBlock;
  wrapperRef?(node: HTMLLIElement): void;
}

export const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed = false,
      style,
      value,
      wrapperRef,
      ...props
    },
    ref,
  ) => {
    const { treeItem, wrapper, blockStyles, iconBadgeStyles } = treeItemStyles({
      ghost,
      clone,
      collapsed,
      invalid: !value.isValid,
    });

    const isReadonly = useSelector(
      templateBuilderStore,
      (store) => store.context.isReadonly,
    );

    const titleSize = match(depth + 1)
      .with(1, 2, 3, 4, (depth) => depth)
      .otherwise(() => 4 as const);

    return (
      <li
        ref={wrapperRef}
        className={wrapper()}
        style={{
          paddingLeft: `${indentationWidth * depth}px`,
        }}
        {...props}
      >
        <Box ref={ref} className={treeItem()} style={style}>
          <Box hStack spaceBetween className="w-full">
            <Box hStack className="overflow-hidden">
              {!isReadonly && (
                <Box {...handleProps}>
                  <Grip />
                </Box>
              )}
              <TooltipLabel label="Toggle Collapse">
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() =>
                    templateBuilderStore.send({
                      type: 'toggleCollapse',
                      id: value.id,
                      collapsed: !collapsed,
                    })
                  }
                >
                  {match(collapsed)
                    .with(true, () => <UnfoldVertical />)
                    .otherwise(() => (
                      <FoldVertical />
                    ))}
                </Button>
              </TooltipLabel>

              <Badge variant="outline" className={iconBadgeStyles()}>
                {!value.isValid && (
                  <TooltipLabel label="This block has validation errors">
                    <AlertCircle />
                  </TooltipLabel>
                )}
                <BlockTypeIcon
                  type={value.type}
                  className={iconBadgeStyles()}
                />
                <P className={labelTextStyles({ size: 2 })}>{value.type}</P>
              </Badge>
              <Separator orientation="vertical" />
              {value.label && (
                <P className={titleTextStyles({ size: titleSize })}>
                  {value.label}
                </P>
              )}
              {value.description && (
                <P
                  className={labelTextStyles({
                    size: 2,
                    className:
                      'max-w-60 overflow-hidden text-ellipsis whitespace-nowrap sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg',
                  })}
                >
                  {value.description}
                </P>
              )}
            </Box>
            {!isReadonly && (
              <Box hStack>
                <BlockPickerPopover
                  parentId={value.parentId}
                  index={value.index + 1}
                  asChild={false}
                >
                  <TooltipLabel label="Add new block below">
                    <Button variant="outline" size="smallIcon">
                      <ArrowDownToLine />
                    </Button>
                  </TooltipLabel>
                </BlockPickerPopover>
                <BlockPickerPopover
                  parentId={value.parentId}
                  index={value.index}
                  asChild={false}
                >
                  <TooltipLabel label="Add new block above">
                    <Button variant="outline" size="smallIcon">
                      <ArrowUpToLine />
                    </Button>
                  </TooltipLabel>
                </BlockPickerPopover>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button variant="destructive" size="smallIcon">
                      <Trash />
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>
                        Are you sure you want to remove this block?
                      </AlertDialogTitle>
                      <AlertDialogDescription>
                        This cannot be reversed.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction
                        buttonProps={{ variant: 'destructive' }}
                        onClick={() =>
                          templateBuilderStore.send({
                            type: 'removeBlock',
                            id: value.id,
                          })
                        }
                      >
                        Remove
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </Box>
            )}
          </Box>
          <Box className={blockStyles({ className: 'p-2' })}>
            <BlockDetailsForm block={value} />
            {match(value)
              .with({ type: 'group' }, (group) =>
                isReadonly ? null : (
                  <GroupBlockControl parentId={group.parentId} group={group} />
                ),
              )
              .otherwise(() => null)}
          </Box>
        </Box>
      </li>
    );
  },
);
