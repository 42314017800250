import { graphql } from '@eluve/graphql.tada';

export const userSettingsFragment = graphql(`
  fragment UserSetting on UserSettings @_unmask {
    __typename
    userId
    promptTemplateId
    promptTemplateIdOrDefault
    enableSoundEffects
  }
`);

export const tenantUserSettingEnableSoundEffectsFragment = graphql(`
  fragment TenantUserSettingEnableSoundEffects on TenantUserSettings @_unmask {
    enableSoundEffects
  }
`);

export const tenantUserSettingFragment = graphql(`
  fragment TenantUserSetting on TenantUserSettings @_unmask {
    __typename
    tenantId
    userId
    customNoteInstructions
    enableSoundEffects
    promptTemplateId
    llmOutputTemplateId
    inputLanguage
    outputLanguage
  }
`);

export const tenantUserSettingsFragment = graphql(
  `
    fragment TenantUserSettings on TenantUsers @_unmask {
      __typename
      tenantId
      userId
      settings {
        ...TenantUserSetting
      }
    }
  `,
  [tenantUserSettingFragment],
);

export const medicalNotesTemplatesFragment = graphql(`
  fragment MedicalNotesTemplates on query_root @_unmask {
    __typename
    activePromptTemplates(
      where: { outputType: { _in: ["SOAP_NOTE", "DYNAMIC_OUTPUT"] } }
      orderBy: { name: ASC }
    ) {
      __typename
      id
      isCurrentDefault
      name
      description
      outputType
    }
    llmOutputTemplates(
      where: { llmOutputType: { _eq: SOAP_NOTE }, isActive: { _eq: true } }
      orderBy: { name: ASC }
    ) {
      __typename
      id
      llmOutputType
      name
    }
  }
`);

export const getTenantUserSettingsQuery = graphql(
  `
    query getTenantUserSettings($tenantId: uuid!, $userId: uuid!) {
      tenantUsersByPk(tenantId: $tenantId, userId: $userId) {
        ...TenantUserSettings
      }
      ...MedicalNotesTemplates
    }
  `,
  [tenantUserSettingsFragment, medicalNotesTemplatesFragment],
);

export const upsertPromptTemplateIdMutation = graphql(`
  mutation upsertPromptTemplateIdForUser(
    $tenantId: uuid!
    $userId: uuid!
    $promptTemplateId: uuid!
    $llmOutputTemplateId: uuid
  ) {
    updateTenantUserSettingsByPk(
      pkColumns: { tenantId: $tenantId, userId: $userId }
      _set: {
        promptTemplateId: $promptTemplateId
        llmOutputTemplateId: $llmOutputTemplateId
      }
    ) {
      __typename
      tenantId
      userId
      promptTemplateId
      llmOutputTemplateId
    }
  }
`);

export const upsertEnableSoundEffectsMutation = graphql(
  `
    mutation upsertEnableSoundEffects(
      $tenantId: uuid!
      $userId: uuid!
      $enableSoundEffects: Boolean!
    ) {
      updateTenantUserSettingsByPk(
        pkColumns: { tenantId: $tenantId, userId: $userId }
        _set: { enableSoundEffects: $enableSoundEffects }
      ) {
        __typename
        tenantId
        userId
        enableSoundEffects
      }
    }
  `,
  [tenantUserSettingFragment],
);

export const updateInputLanguageMutation = graphql(`
  mutation upsertInputLanguageMutation(
    $tenantId: uuid!
    $userId: uuid!
    $inputLanguage: String
  ) {
    updateTenantUserSettingsByPk(
      pkColumns: { tenantId: $tenantId, userId: $userId }
      _set: { inputLanguage: $inputLanguage }
    ) {
      __typename
      tenantId
      userId
      inputLanguage
    }
  }
`);

export const updateOutputLanguageMutation = graphql(`
  mutation upsertOutputLanguageMutation(
    $tenantId: uuid!
    $userId: uuid!
    $outputLanguage: String
  ) {
    updateTenantUserSettingsByPk(
      pkColumns: { tenantId: $tenantId, userId: $userId }
      _set: { outputLanguage: $outputLanguage }
    ) {
      __typename
      tenantId
      userId
      outputLanguage
    }
  }
`);
