import { useSuspenseQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import React, { useMemo } from 'react';
import { toast } from 'sonner';

import { useCompleteFragment } from '@eluve/apollo-client';
import { Switch } from '@eluve/components';
import { ColDefBuilder, DataTable, H3, P } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useTenantIdFromParams } from '@eluve/session-helpers';

const tenantLlmOutputTemplateFragment = graphql(`
  fragment TenantLlmOutputTemplate on TenantLlmOutputTemplates @_unmask {
    __typename
    tenantId
    llmOutputTemplateId
    isEnabled
    updatedAt
  }
`);

const tenantLlmOutputTemplatesFragment = graphql(
  `
    fragment TenantLlmOutputTemplates on Tenants @_unmask {
      __typename
      id
      llmOutputTemplates {
        ...TenantLlmOutputTemplate
        llm_output_template {
          __typename
          id
          name
          description
          llmOutputType
        }
      }
    }
  `,
  [tenantLlmOutputTemplateFragment],
);

const getLlmOutputTemplatesQuery = graphql(
  `
    query getLlmOutputTemplates($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        ...TenantLlmOutputTemplates
      }
    }
  `,
  [tenantLlmOutputTemplatesFragment],
);

const setTenantLlmOutputTemplateMutation = graphql(
  `
    mutation setTenantLlmOutputTemplate(
      $tenantId: uuid!
      $llmOutputTemplateId: uuid!
      $isEnabled: Boolean!
    ) {
      insertTenantLlmOutputTemplatesOne(
        object: {
          tenantId: $tenantId
          llmOutputTemplateId: $llmOutputTemplateId
          isEnabled: $isEnabled
        }
        onConflict: {
          constraint: tenant_llm_output_templates_pkey
          updateColumns: [isEnabled]
        }
      ) {
        ...TenantLlmOutputTemplate
      }
    }
  `,
  [tenantLlmOutputTemplateFragment],
);

type OutputTemplateRow = {
  isEnabled: boolean;
  id: string;
  name: string;
  description: string;
  outputType: string;
  tenantId: string;
};

const TenantLlmOutputTemplateToggle: React.FC<{
  tenantId: string;
  name: string;
  llmOutputTemplateId: string;
}> = ({ tenantId, name, llmOutputTemplateId }) => {
  const data = useCompleteFragment({
    fragment: tenantLlmOutputTemplateFragment,
    key: {
      llmOutputTemplateId,
      tenantId,
    },
    strict: false,
  });

  const [updateTenantLlmOutputTemplate] = useMutation(
    setTenantLlmOutputTemplateMutation,
    {
      onCompleted: () =>
        toast.success(`Access to LLM output template ${name} updated`),
      onError: () =>
        toast.error(`Failed to update access to LLM output template ${name}`),
      optimisticResponse: (data) => ({
        insertTenantLlmOutputTemplatesOne: {
          __typename: 'TenantLlmOutputTemplates' as const,
          updatedAt: new Date().toISOString(),
          ...data,
        },
      }),
    },
  );

  const submit = async (isEnabled: boolean) => {
    try {
      await updateTenantLlmOutputTemplate({
        context: {
          headers: {
            'x-hasura-role': 'eluve-admin',
          },
        },
        variables: {
          tenantId,
          llmOutputTemplateId,
          isEnabled,
        },
      });
    } catch (e) {
      toast.error(`Failed to update access to prompt template ${name}`);
    }
  };

  return (
    <Switch
      isCompact
      onCheckedChange={(isChecked) => submit(isChecked)}
      checked={data?.isEnabled ?? false}
    />
  );
};

const columns = new ColDefBuilder<OutputTemplateRow>()
  .linkSortable('name', (row) => `/admin/output-templates/${row.id}`)
  .defaultSortable('description')
  .defaultSortable('outputType', 'Output Type')
  .defaultSortable('isEnabled', {
    label: 'Is Enabled',
    cellRenderer: (row) => {
      return (
        <TenantLlmOutputTemplateToggle
          tenantId={row.tenantId}
          llmOutputTemplateId={row.id}
          name={row.name}
        />
      );
    },
  })
  .build();

export const AdminTenantLlmOutputTemplatesPage: React.FC = () => {
  const tenantId = useTenantIdFromParams()!;

  const { data } = useSuspenseQuery(getLlmOutputTemplatesQuery, {
    variables: {
      tenantId,
    },
    context: {
      headers: {
        'x-hasura-role': 'eluve-admin',
      },
    },
  });

  const rows = useMemo(() => {
    return (data?.tenantsByPk?.llmOutputTemplates ?? []).map<OutputTemplateRow>(
      (llmOutputTemplate) => ({
        id: llmOutputTemplate.llm_output_template.id,
        name: llmOutputTemplate.llm_output_template.name,
        tenantId,
        description: llmOutputTemplate.llm_output_template.description ?? '',
        outputType: llmOutputTemplate.llm_output_template.llmOutputType,
        isEnabled: llmOutputTemplate.isEnabled,
      }),
    );
  }, [data, tenantId]);

  return (
    <div className="grid gap-2">
      <div>
        <H3>LLM Output Templates</H3>
        <P>
          Control which LLM output templates this tenant will have access to
        </P>
      </div>
      <DataTable
        data={rows}
        columns={columns}
        isPaginated={false}
        enableGlobalSearch
        isCompact
      />
    </div>
  );
};
