import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';
import { toast } from 'sonner';

import {
  DynamicArtifactTemplateBuilder,
  templateBuilderStore,
} from '@eluve/blocks';
import { Button, PageTitle } from '@eluve/components';
import { DynamicArtifactTemplate } from '@eluve/llm-outputs';

import { createOutputTemplateMutation } from './operations';

export const CreateOutputTemplatePage: React.FC = () => {
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const [createOutputTemplate] = useMutation(createOutputTemplateMutation, {
    onCompleted: ({ insertLlmOutputTemplatesOne: template }) => {
      toast.success('Output template created');
      templateBuilderStore.send({ type: 'reset' });
      if (template) {
        navigate(`/admin/output-templates/${template.id}`);
      }
    },
    onError: () => toast.error('Failed to create output template'),
  });

  useMount(() => {
    templateBuilderStore.send({ type: 'reset' });
  });

  const handleSubmit = () => {
    const { context } = templateBuilderStore.getSnapshot();
    const dynamicArtifact: DynamicArtifactTemplate = {
      name: context.name,
      description: context.description,
      blocks: context.blocks,
    };

    createOutputTemplate({
      variables: {
        input: {
          name: context.name,
          description: context.description,
          llmOutputType: context.outputType,
          dynamicArtifactTemplate: dynamicArtifact,
        },
      },
    });
  };

  return (
    <div className="container">
      <div className="flex items-center gap-4">
        <PageTitle cName="flex-1">Create an Output Template</PageTitle>
      </div>
      <Button disabled={!isValid} onClick={handleSubmit}>
        Submit
      </Button>
      <DynamicArtifactTemplateBuilder onIsValidChange={setIsValid} />
    </div>
  );
};
