import { useMutation, useSuspenseQuery } from '@apollo/client';
import React, { ReactNode, useState } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'sonner';
import { P, match } from 'ts-pattern';

import { useCompleteFragment } from '@eluve/apollo-client';
import { usePrivacyModeToggle } from '@eluve/blocks';
import {
  Box,
  Button,
  FCC,
  H3,
  HStack,
  Kbd,
  PageTitle,
  ReskinContent,
  ReskinHeader,
  ReskinMain,
  Separator,
  SidebarDefaultTrigger,
  Switch,
  Textarea,
  VStack,
  titleTextStyles,
} from '@eluve/components';
import {
  EnableSoundEffects,
  LanguageSettings,
  PromptTemplateSettings,
} from '@eluve/frontend-feature-user-settings';
import { graphql } from '@eluve/graphql.tada';
import {
  useAssignedTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';
import {
  FeatureFlaggedComponent,
  UserFeatureFlaggedComponent,
} from '@eluve/smart-blocks';
import { useBeforeUnload } from '@eluve/utility-hooks';

import { useShowAppointmentsOwner } from '../../../settings/hooks/useShowAppointmentsOwner';

import { MicSettings } from './MicSettings';

const isString = (x: unknown): x is string => typeof x === 'string';

const customInstructionsFragment = graphql(`
  fragment CustomInstructions on TenantUserSettings {
    __typename
    userId
    tenantId
    customNoteInstructions
  }
`);

const updateCustomInstructionsMutation = graphql(
  `
    mutation UpdateCustomInstructions(
      $tenantId: uuid!
      $userId: uuid!
      $instructions: String!
    ) {
      updateTenantUserSettingsByPk(
        pkColumns: { tenantId: $tenantId, userId: $userId }
        _set: { customNoteInstructions: $instructions }
      ) {
        ...CustomInstructions
      }
    }
  `,
  [customInstructionsFragment],
);

const getTenantUserSettingsQuery = graphql(
  `
    query getTenantUserSettings($tenantId: uuid!, $userId: uuid!) {
      tenantUserSettingsByPk(tenantId: $tenantId, userId: $userId) {
        ...CustomInstructions
      }
    }
  `,
  [customInstructionsFragment],
);

const SettingsSection: FCC<{ title: string; description?: ReactNode }> = ({
  title,
  description,
  children,
}) => {
  return (
    <Box className="flex w-full flex-col justify-between gap-2 md:flex-row">
      <Box className="lg:w-128 w-80 md:w-96">
        <h3 className={titleTextStyles({ size: 1 })}>{title}</h3>
      </Box>
      <VStack>
        {description &&
          match(description)
            .with(P.when(isString), (d) => (
              <h4 className={titleTextStyles({ size: 3 })}>{d}</h4>
            ))
            .otherwise(() => description)}
        {children}
      </VStack>
    </Box>
  );
};

export const TenantUserSettingsPage: React.FC = () => {
  const tenantId = useAssignedTenantIdFromParams();
  const userId = useUserIdFromSession();
  const { setPrivacyMode, privacyMode } = usePrivacyModeToggle();
  const [showAppointmentsOwner, setShowAppointmentsOwner] =
    useShowAppointmentsOwner();

  useSuspenseQuery(getTenantUserSettingsQuery, {
    variables: { tenantId, userId },
  });

  const [setCustomInstructions] = useMutation(
    updateCustomInstructionsMutation,
    {
      onCompleted: () => toast.success('Instructions updated'),
      onError: () => toast.error('Failed to save instructions'),
    },
  );

  const { customNoteInstructions } =
    useCompleteFragment({
      fragment: customInstructionsFragment,
      key: { tenantId, userId },
      strict: false,
    }) ?? {};

  const [instructions, setInstructions] = useState<string>(
    customNoteInstructions ?? '',
  );

  const unsavedChanges =
    (Boolean(instructions) || Boolean(customNoteInstructions)) &&
    (instructions ?? null) !== (customNoteInstructions ?? null);

  useBeforeUnload(unsavedChanges);

  const saveInstructions = async () => {
    await setCustomInstructions({
      variables: { tenantId, userId, instructions },
    });
  };

  return (
    <>
      <Helmet>
        <title>Settings | Eluve</title>
      </Helmet>
      <ReskinMain>
        <ReskinHeader>
          <SidebarDefaultTrigger />
          <PageTitle>Settings</PageTitle>
        </ReskinHeader>
        <ReskinContent>
          <VStack gap={6} className="py-4">
            <H3>Audio Settings</H3>
            <SettingsSection
              title="Microphone"
              description="Test and pick your preferred audio input device"
            >
              <MicSettings />
            </SettingsSection>

            <Separator />

            <H3>User Settings</H3>
            <SettingsSection
              title="Preferred Chart Style"
              description="Select your preferred default output style"
            >
              <PromptTemplateSettings className="w-full max-w-96" />
            </SettingsSection>

            <FeatureFlaggedComponent flag="MULTILINGUAL_SUPPORT">
              <SettingsSection title="Language">
                <LanguageSettings />
              </SettingsSection>
            </FeatureFlaggedComponent>

            <SettingsSection
              title="Privacy Mode"
              description={
                <h4 className={titleTextStyles({ size: 3 })}>
                  Enable privacy mode to hide patient information in the UI. It
                  can be toggled from anywhere in the app by pressing the
                  keyboard shortcut <Kbd>Shift</Kbd> + <Kbd>P</Kbd>.
                </h4>
              }
            >
              <HStack gap={4} asChild>
                <label>
                  <Switch
                    checked={privacyMode}
                    onCheckedChange={(checked) => setPrivacyMode(!checked)}
                  />
                  <i className="text-gray-11 text-sm">
                    Ex : Patient name:
                    <strong className="privacy-text"> John Doe</strong>
                  </i>
                </label>
              </HStack>
            </SettingsSection>
            <SettingsSection
              title="Sound Effects"
              description="Play sound effects when starting/stopping recordings."
            >
              <EnableSoundEffects />
            </SettingsSection>
            <SettingsSection
              title="Show Appointment's Owner"
              description="Displays the name of the owner of appointments in the agenda view"
            >
              <HStack gap={4}>
                <label>
                  <Switch
                    checked={showAppointmentsOwner}
                    onCheckedChange={(checked) =>
                      setShowAppointmentsOwner(checked)
                    }
                  />
                </label>
              </HStack>
            </SettingsSection>

            <UserFeatureFlaggedComponent flag="USER_PREFERENCES_CUSTOM_INSTRUCTIONS">
              <Separator />
              <SettingsSection
                title="Custom Instructions"
                description="Customize your notes by providing specific guidelines. Try to be as clear and concise as possible for best results"
              >
                <Textarea
                  placeholder="Type your instructions here"
                  rows={6}
                  value={instructions}
                  onChange={(e) => {
                    setInstructions(e.target.value);
                  }}
                />
                <Button disabled={!unsavedChanges} onClick={saveInstructions}>
                  Save
                </Button>
              </SettingsSection>
            </UserFeatureFlaggedComponent>
          </VStack>
        </ReskinContent>
      </ReskinMain>
    </>
  );
};
