import { Slot } from '@radix-ui/react-slot';
import React, { ElementType } from 'react';
import { VariantProps, tv } from 'tailwind-variants';

import { flexVariants } from './flexVariants';

const hStackVariants = tv({
  extend: flexVariants,
  variants: {
    wrap: {
      true: 'flex-wrap',
    },
    inline: {
      true: 'inline-flex max-w-full',
    },
  },
  defaultVariants: {
    align: 'center',
    gap: 2,
  },
});

export interface HStackProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof hStackVariants> {
  asChild?: boolean;
  as?: ElementType;
}

const HStack = React.forwardRef<HTMLDivElement, HStackProps>(
  (
    {
      gap,
      align,
      justify,
      wFull,
      className,
      asChild,
      inline,
      wrap,
      as: Ele = 'div',
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : Ele;
    return (
      <Comp
        className={hStackVariants({
          gap,
          align,
          justify,
          wFull,
          className,
          inline,
          wrap,
        })}
        ref={ref}
        {...props}
      />
    );
  },
);

HStack.displayName = 'HStack';

export { HStack, hStackVariants };
