import { graphql } from '@eluve/graphql.tada';

export const getOutputTemplateQuery = graphql(`
  query getOutputTemplate($id: uuid!) {
    llmOutputTemplatesByPk(id: $id) {
      __typename
      id
      name
      dynamicArtifactTemplate
      llmOutputType
    }
  }
`);

export const createOutputTemplateMutation = graphql(`
  mutation createOutputTemplate($input: LlmOutputTemplatesInsertInput!) {
    insertLlmOutputTemplatesOne(object: $input) {
      __typename
      id
    }
  }
`);
