import { zodResolver } from '@hookform/resolvers/zod';
import { useSelector } from '@xstate/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useMount } from 'react-use';
import { z } from 'zod';

import {
  Box,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  HStack,
  Input,
  Switch,
  Textarea,
} from '@eluve/components';

import { templateBuilderStore } from './templateBuilderStore';
import { BuilderBlock } from './utilities';

const blockDetailsFormSchema = z.object({
  label: z.string().min(3),
  description: z.string().optional(),
  isRequired: z.boolean(),
});

type BlockDetailsFormData = z.infer<typeof blockDetailsFormSchema>;

export interface BlockDetailsFormProps {
  block: BuilderBlock;
}

export const BlockDetailsForm: React.FC<BlockDetailsFormProps> = ({
  block,
}) => {
  const isReadonly = useSelector(
    templateBuilderStore,
    (store) => store.context.isReadonly,
  );
  const form = useForm<BlockDetailsFormData>({
    resolver: zodResolver(blockDetailsFormSchema),
    mode: 'all',
    values: {
      label: block.label,
      description: block.description,
      isRequired: block.isRequired ?? false,
    },
  });

  const {
    watch,
    formState: { isValid },
  } = form;

  useMount(() => {
    form.trigger();
  });

  const formData = watch();

  const onSubmit = (data: BlockDetailsFormData) => {
    if (!isReadonly) {
      templateBuilderStore.send({
        type: 'setBlockDetails',
        id: block.id,
        label: data.label,
        description: data.description,
        isRequired: data.isRequired,
      });
    }
  };

  return (
    <Box vStack className="w-full gap-1">
      <Form {...form}>
        <form
          className="w-full space-y-3"
          onBlur={() => {
            if (isValid) {
              onSubmit(formData);
            }
          }}
        >
          <FormField
            control={form.control}
            name="label"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Label</FormLabel>
                <FormControl>
                  <Input placeholder="Label" {...field} disabled={isReadonly} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Description"
                    {...field}
                    disabled={isReadonly}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="isRequired"
            render={({ field }) => (
              <HStack asChild>
                <FormItem>
                  <FormLabel className="pt-2">Required</FormLabel>
                  <Switch
                    disabled={isReadonly}
                    onCheckedChange={(checked) => field.onChange(checked)}
                    checked={field.value}
                  />
                </FormItem>
              </HStack>
            )}
          />
        </form>
      </Form>
    </Box>
  );
};
