import * as React from 'react';
import { tv } from 'tailwind-variants';

import { cn } from './cn';
import { FCC } from './types';

type SpaceDirection = 'x' | 'y' | 't' | 'b' | 'l' | 'r' | '';
type SpaceSize = 0 | 1 | 2 | 3 | 4;

type PaddingSpaceClass = `p${SpaceDirection}-${SpaceSize}`;
type MarginSpaceClass = `m${SpaceDirection}-${SpaceSize}`;

type SpaceProps = {
  m?: MarginSpaceClass | MarginSpaceClass[];
  p?: PaddingSpaceClass | PaddingSpaceClass[];
  cn?: string;
};

type HeadingProps = SpaceProps & { children: React.ReactNode };

const spacePropsToClasses = ({ m = [], p = [], cn = '' }: SpaceProps) => {
  return [...(Array.isArray(m) ? m : [m]), ...(Array.isArray(p) ? p : [p]), cn];
};

export const headerTextStyles = tv({
  base: 'text-content1',
  variants: {
    size: {
      0: 'text-header0',
      1: 'text-header1',
      2: 'text-header2',
      3: 'text-header3',
      4: 'text-header4',
      5: 'text-header5',
      6: 'text-header6',
    },
  },
  defaultVariants: {
    size: 1,
  },
});

export const subHeaderTextStyles = tv({
  base: 'text-content1',
  variants: {
    size: {
      1: 'text-subHeader1',
      2: 'text-subHeader2',
    },
  },
  defaultVariants: {
    size: 1,
  },
});

export const titleTextStyles = tv({
  base: 'text-content2',
  variants: {
    size: {
      1: 'text-title1',
      2: 'text-title2',
      3: 'text-title3',
      4: 'text-title4',
    },
  },
  defaultVariants: {
    size: 1,
  },
});

export const bodyTextStyles = tv({
  base: 'text-content3',
  variants: {
    size: {
      1: 'text-body1',
      2: 'text-body2',
    },
  },
  defaultVariants: {
    size: 1,
  },
});

export const labelTextStyles = tv({
  base: 'text-content3',
  variants: {
    size: {
      1: 'text-label1',
      2: 'text-label2',
    },
  },
  defaultVariants: {
    size: 1,
  },
});

export const H1: FCC<{ xl?: boolean } & SpaceProps> = ({
  children,
  xl = false,
  ...space
}) => {
  return (
    <h1
      className={cn(
        headerTextStyles({
          size: xl ? 0 : 1,
        }),
        spacePropsToClasses(space),
      )}
    >
      {children}
    </h1>
  );
};

export const H2: FCC<SpaceProps> = ({ children, ...space }) => {
  return (
    <h5
      className={cn(
        headerTextStyles({
          size: 2,
        }),
        spacePropsToClasses(space),
      )}
    >
      {children}
    </h5>
  );
};

export const H3: FCC<SpaceProps> = ({ children, ...space }) => {
  return (
    <h5
      className={cn(
        headerTextStyles({
          size: 3,
        }),
        spacePropsToClasses(space),
      )}
    >
      {children}
    </h5>
  );
};

export const H4: FCC<SpaceProps> = ({ children, ...space }) => {
  return (
    <h5
      className={cn(
        headerTextStyles({
          size: 4,
        }),
        spacePropsToClasses(space),
      )}
    >
      {children}
    </h5>
  );
};

export const H5: FCC<SpaceProps> = ({ children, ...space }) => {
  return (
    <h5
      className={cn(
        headerTextStyles({
          size: 5,
        }),
        spacePropsToClasses(space),
      )}
    >
      {children}
    </h5>
  );
};

export const H6 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ children, ...space }, ref) => {
    return (
      <h6
        ref={ref}
        className={cn(
          headerTextStyles({
            size: 6,
          }),
          spacePropsToClasses(space),
        )}
      >
        {children}
      </h6>
    );
  },
);

H6.displayName = 'H6';

export const P: FCC<{ className?: string }> = ({ children, className }) => {
  return (
    <p className={cn(bodyTextStyles({ size: 2 }), className)}>{children}</p>
  );
};
