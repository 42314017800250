import { useMutation } from '@apollo/client';
import { toast } from 'sonner';

import { useCompleteFragment } from '@eluve/apollo-client';
import { InputLabel } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { TemplateSelector } from '@eluve/smart-blocks';

interface NewAppointmentTemplateProps {
  tenantId: string;
  appointmentId: string;
}

const AppointmentTemplateFragment = graphql(`
  fragment AppointmentTemplate on Appointments {
    __typename
    id
    initialPromptTemplateId
    initialLlmOutputTemplateId
  }
`);

const updateAppointmentTemplateMutation = graphql(`
  mutation UpdateAppointmentTemplateMutation(
    $tenantId: uuid!
    $id: uuid!
    $initialPromptTemplateId: uuid!
    $initialLlmOutputTemplateId: uuid
  ) {
    updateAppointmentsByPk(
      pkColumns: { tenantId: $tenantId, id: $id }
      _set: {
        initialPromptTemplateId: $initialPromptTemplateId
        initialLlmOutputTemplateId: $initialLlmOutputTemplateId
      }
    ) {
      __typename
      id
      initialPromptTemplateId
      initialLlmOutputTemplateId
    }
  }
`);

export const AppointmentTemplateSelector: React.FC<
  NewAppointmentTemplateProps
> = ({ tenantId, appointmentId }) => {
  const data = useCompleteFragment({
    fragment: AppointmentTemplateFragment,
    key: {
      id: appointmentId,
    },
  });

  const [updateAppointmentTemplates] = useMutation(
    updateAppointmentTemplateMutation,
    {
      optimisticResponse: ({
        initialLlmOutputTemplateId,
        initialPromptTemplateId,
        id,
      }) => ({
        __typename: 'mutation_root',
        updateAppointmentsByPk: {
          __typename: 'Appointments' as const,
          id,
          initialPromptTemplateId: initialPromptTemplateId,
          initialLlmOutputTemplateId: initialLlmOutputTemplateId ?? null,
        },
      }),
      onError: () => {
        toast.error('Unable to update appointment template');
      },
    },
  );

  return (
    <InputLabel label="Template">
      <TemplateSelector
        selectedPromptTemplateId={data.initialPromptTemplateId ?? undefined}
        onSelectionChanged={({
          initialPromptTemplateId,
          initialOutputTemplateId,
        }) =>
          updateAppointmentTemplates({
            variables: {
              tenantId,
              id: appointmentId,
              initialPromptTemplateId,
              initialLlmOutputTemplateId: initialOutputTemplateId,
            },
          })
        }
        selectedOutputTemplateId={data.initialLlmOutputTemplateId ?? undefined}
      />
    </InputLabel>
  );
};
