import { UniqueIdentifier } from '@dnd-kit/core';
import { useSelector } from '@xstate/store/react';
import React from 'react';

import {
  Box,
  FCC,
  HStack,
  P,
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
  Separator,
  labelTextStyles,
} from '@eluve/components';
import { BlockType } from '@eluve/llm-outputs';

import { BlockTypeIcon } from './BlockTypeIcon';
import { templateBuilderStore } from './templateBuilderStore';

const blocks: {
  name: string;
  tip: string;
  type: BlockType;
}[] = [
  {
    type: 'group',
    name: 'Group',
    tip: 'A container for other blocks',
  },
  {
    type: 'text',
    name: 'Text block',
    tip: 'A rich textbox that can be used to capture written information',
  },
  {
    type: 'range',
    name: 'Range Slider',
    tip: 'A slider that allows users to select a value within a range',
  },
];

export const BlockPicker: React.FC<{
  parentId: UniqueIdentifier | null;
  index?: number;
}> = ({ parentId, index }) => {
  const showTips = useSelector(
    templateBuilderStore,
    (state) => state.context.showTips,
  );

  const onBlockClick = (blockType: BlockType) => {
    templateBuilderStore.send({
      parentId,
      type: 'addBlock',
      blockType,
      placeAtIndex: index,
    });
  };

  return (
    <Box vStack className="gap-0 p-0">
      {blocks.map(({ name, tip, type }, i) => {
        return (
          <React.Fragment key={i}>
            <PopoverClose asChild>
              <HStack
                align="start"
                className="hover:bg-gray-2 p-2 hover:cursor-pointer"
                onClick={() => onBlockClick(type)}
              >
                <BlockTypeIcon type={type} />
                <Box vStack>
                  <P>{name}</P>
                  {showTips && <p className={labelTextStyles()}>{tip}</p>}
                </Box>
              </HStack>
            </PopoverClose>
            {i < blocks.length - 1 && <Separator />}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export const BlockPickerPopover: FCC<{
  parentId: UniqueIdentifier | null;
  index?: number;
  isOpenChange?: (isOpen: boolean) => void;
  asChild?: boolean;
}> = ({ children, index, isOpenChange, parentId, asChild = true }) => {
  return (
    <Popover onOpenChange={(isOpen) => isOpenChange?.(isOpen)}>
      <PopoverTrigger asChild={asChild}>{children}</PopoverTrigger>
      <PopoverContent className="p-0">
        <BlockPicker parentId={parentId} index={index} />
      </PopoverContent>
    </Popover>
  );
};
