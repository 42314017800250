import { Slot } from '@radix-ui/react-slot';
import React from 'react';
import { VariantProps, tv } from 'tailwind-variants';

const boxVariants = tv({
  base: '',
  variants: {
    hStack: {
      true: 'flex flex-row items-center gap-2',
    },
    row: {
      true: 'flex flex-row gap-2',
    },
    spaceBetween: {
      true: 'flex justify-between',
    },
    vStack: {
      true: 'flex flex-col items-start gap-2',
    },
    zStack: {
      true: 'grid grid-cols-1 grid-rows-1 *:relative *:z-auto *:col-start-1 *:row-start-1',
    },
    fullWidth: {
      true: 'w-full',
    },
    center: {
      true: 'flex items-center justify-center',
    },
    gap: {
      1: '',
      2: '',
      3: '',
      4: '',
    },
  },
  compoundVariants: [
    {
      hStack: true,
      gap: 1,
      className: 'gap-1',
    },
    {
      vStack: true,
      gap: 1,
      className: 'gap-1',
    },
    {
      hStack: true,
      gap: 2,
      className: 'gap-2',
    },
    {
      vStack: true,
      gap: 2,
      className: 'gap-2',
    },
    {
      hStack: true,
      gap: 3,
      className: 'gap-3',
    },
    {
      vStack: true,
      gap: 3,
      className: 'gap-3',
    },
    {
      hStack: true,
      gap: 4,
      className: 'gap-4',
    },
    {
      vStack: true,
      gap: 4,
      className: 'gap-4',
    },
  ],
});

export interface BoxProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof boxVariants> {
  asChild?: boolean;
}

const Box = React.forwardRef<HTMLDivElement, BoxProps>(
  (
    {
      className,
      hStack,
      vStack,
      row,
      fullWidth,
      center,
      zStack,
      spaceBetween,
      gap,
      asChild = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'div';
    return (
      <Comp
        className={boxVariants({
          hStack,
          vStack,
          zStack,
          row,
          fullWidth,
          center,
          spaceBetween,
          gap,
          className,
        })}
        ref={ref}
        {...props}
      />
    );
  },
);

Box.displayName = 'Box';

export { Box, boxVariants };
