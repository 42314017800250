import { useSuspenseQuery } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  DynamicArtifactTemplateBuilder,
  templateBuilderStore,
} from '@eluve/blocks';
import { HStack, PageTitle } from '@eluve/components';
import { dynamicArtifactTemplateSchema } from '@eluve/llm-outputs';

import { getOutputTemplateQuery } from './operations';

export const OutputTemplateDetailsPage: React.FC = () => {
  const { outputTemplateId } = useParams() as { outputTemplateId: string };

  const {
    data: { llmOutputTemplatesByPk: template },
  } = useSuspenseQuery(getOutputTemplateQuery, {
    variables: {
      id: outputTemplateId,
    },
  });

  const artifactTemplate = useMemo(() => {
    const parsed = dynamicArtifactTemplateSchema.safeParse(
      template?.dynamicArtifactTemplate,
    );
    if (!parsed.success) {
      return undefined;
    }

    return parsed.data;
  }, [template?.dynamicArtifactTemplate]);

  useEffect(() => {
    if (artifactTemplate) {
      templateBuilderStore.send({
        type: 'hydrateArtifact',
        artifactTemplate,
        outputType: template?.llmOutputType ?? 'SOAP_NOTE',
      });
    }
  }, [artifactTemplate, template?.llmOutputType]);

  return (
    <>
      <HStack gap={4}>
        <PageTitle cName="flex-1">
          {artifactTemplate?.name ?? template?.name}
        </PageTitle>
      </HStack>
      <DynamicArtifactTemplateBuilder isReadonly={true} />
    </>
  );
};
