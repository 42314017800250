import S, { ObjectSchema } from 'fluent-json-schema';
import { produce } from 'immer';
import { z } from 'zod';

import { AppointmentSummary } from '@eluve/utils';

const baseBlock = z.object({
  key: z.string(),
  label: z.string(),
  isRequired: z.boolean().optional(),
  description: z.string().optional(),
});

const textField = baseBlock.extend({
  type: z.literal('text'),
  placeholderText: z.string().optional(),
  text: z.string().optional(),
});

export type TextFieldBlock = z.infer<typeof textField>;

const checkbox = baseBlock.extend({
  type: z.literal('checkbox'),
  options: z.array(
    z.object({
      label: z.string(),
      isChecked: z.boolean(),
    }),
  ),
});

export type CheckboxBlock = z.infer<typeof checkbox>;

const range = baseBlock.extend({
  type: z.literal('range'),
  min: z.number(),
  max: z.number(),
  step: z.number(),
  value: z.number().optional(),
});

export type RangeBlock = z.infer<typeof range>;

const groupableBlocks = z.discriminatedUnion('type', [
  textField,
  checkbox,
  range,
]);

type GroupableBlocks = z.infer<typeof groupableBlocks>;

type RecursiveGroup = z.infer<typeof baseBlock> & {
  type: 'group';
  blocks: Array<RecursiveGroup | GroupableBlocks>;
};

const group: z.ZodType<RecursiveGroup> = baseBlock.extend({
  type: z.literal('group'),
  blocks: z.array(z.union([groupableBlocks, z.lazy(() => group)])),
});

export type GroupBlock = z.infer<typeof group>;

const block = z.union([group, groupableBlocks]);
export type Block = z.infer<typeof block>;
export type BlockType = Block['type'];

export const dynamicArtifactTemplateSchema = z.object({
  name: z.string().default('Dynamic Artifact'),
  description: z.string().optional(),
  blocks: z.array(block).default([]),
});

export type DynamicArtifactTemplate = z.infer<
  typeof dynamicArtifactTemplateSchema
>;

/**
 * Given a dynamic artifact template and data, hydrate the template with the data
 * by recursively iterating through the blocks and setting the values from the corresponding
 * keys on the data
 */
export const hydrateDynamicArtifactTemplate = (
  template: DynamicArtifactTemplate,
  data: Record<string, unknown>,
) => {
  const mapDataToBlocks = (blocks: Block[], data: Record<string, unknown>) => {
    blocks.forEach((block) => {
      switch (block.type) {
        case 'group': {
          data[block.key] &&
            mapDataToBlocks(
              block.blocks,
              data[block.key] as Record<string, unknown>,
            );
          break;
        }
        case 'text': {
          block.text = data[block.key] as string;
          break;
        }
        case 'checkbox': {
          block.options.forEach((option) => {
            option.isChecked = data[block.key] === option.label;
          });
          break;
        }
        case 'range': {
          block.value = data[block.key] as number;
          break;
        }
      }
    });
  };

  const result = produce(template, (draft) => {
    mapDataToBlocks(draft.blocks, data);
  });

  return result;
};

export const convertDynamicArtifactToJsonSchema = (
  artifact: DynamicArtifactTemplate,
): ObjectSchema<Record<string, any>> => {
  const { blocks } = artifact;
  const schema = S.object()
    .description(artifact.description ?? artifact.name)
    .additionalProperties(false);

  const convertBlocksToJsonSchema = (blocks: Block[], schema: ObjectSchema) => {
    blocks.forEach((block) => {
      const { key, description, isRequired = false } = block;
      switch (block.type) {
        case 'group': {
          let groupObj = S.object().additionalProperties(false);

          groupObj = description ? groupObj.description(description) : groupObj;

          const result = convertBlocksToJsonSchema(block.blocks, groupObj);

          schema = schema.prop(key, result).required();

          break;
        }
        case 'text': {
          let prop = S.string();
          prop = block.description ? prop.description(block.description) : prop;
          schema = schema
            .prop(key, isRequired ? prop : S.anyOf([prop, S.null()]))
            .required();
          break;
        }
        case 'checkbox':
          // TODO(marsela)[ELU-2543]: handle isRequired for checkboxes
          schema = schema.prop(
            key,
            S.object().prop('type', S.const('checkbox')),
          );
          break;
        case 'range': {
          const { min, max } = block;
          let prop = S.number().minimum(min).maximum(max);

          prop = description ? prop.description(description) : prop;

          schema = schema
            .prop(key, isRequired ? prop : S.anyOf([prop, S.null()]))
            .required();
          break;
        }
      }
    });
    return schema;
  };

  const resultSchema = convertBlocksToJsonSchema(blocks, schema);

  // return schema;
  return resultSchema;
};

export type ClassicSummary = {
  type: 'SOAP';
  data: AppointmentSummary | null;
};

export type DynamicSummary = {
  type: 'DYNAMIC';
  blocks: Block[];
};
