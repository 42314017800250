import { useQuery } from '@apollo/client';

import {
  ClassicSummary,
  DynamicSummary,
  dynamicArtifactTemplateSchema,
  hydrateDynamicArtifactTemplate,
} from '@eluve/llm-outputs';
import { AppointmentSummarySchema } from '@eluve/utils';

import { useAppointmentContext } from './appointment.context';
import { getAllCompletedOutputs, getAllSummaries } from './operations';

type LlmSummary = {
  summaryId: string;
  llmModelPromptTemplateId: string | null;
  promptTemplateId: string | null;
  summary: ClassicSummary | DynamicSummary;
  isSummaryAvailable: boolean;
  summarizationCompletedAt: string | null;
};

export const useAllSummaries = (opts?: {
  soapNotesOnly: boolean;
}): {
  summaries: LlmSummary[];
} => {
  const { soapNotesOnly = true } = opts ?? {};
  const { tenantId, appointmentId } = useAppointmentContext();
  const { data } = useQuery(
    soapNotesOnly ? getAllSummaries : getAllCompletedOutputs,
    {
      variables: {
        tenantId,
        appointmentId,
      },
    },
  );

  const llmOutputs = data?.appointmentsByPk?.appointment_llm_outputs ?? [];

  const summaries = llmOutputs.map<LlmSummary>((llmSummary) => {
    const summary = llmSummary?.output?.content ?? null;
    const hasDynamicOutputTemplate = Boolean(
      llmSummary?.output?.outputTemplate?.dynamicArtifactTemplate,
    );

    const shared: Omit<LlmSummary, 'summary' | 'isSummaryAvailable'> = {
      summaryId: llmSummary.llmOutputId,
      llmModelPromptTemplateId: llmSummary.output.modelPromptTemplate.id,
      promptTemplateId: llmSummary.output.modelPromptTemplate.promptTemplateId,
      summarizationCompletedAt: llmSummary.output.completedAt,
    };

    if (hasDynamicOutputTemplate) {
      const artifactTemplate = dynamicArtifactTemplateSchema.parse(
        llmSummary?.output?.outputTemplate?.dynamicArtifactTemplate ?? {},
      );

      const hydrated = hydrateDynamicArtifactTemplate(
        artifactTemplate,
        summary ?? {},
      );

      return {
        ...shared,
        isSummaryAvailable: true,
        summary: {
          type: 'DYNAMIC',
          blocks: hydrated.blocks,
        },
      };
    }

    const parsed = AppointmentSummarySchema.safeParse(
      llmSummary?.output?.content,
    );

    if (parsed.success) {
      return {
        ...shared,
        summary: {
          type: 'SOAP',
          data: parsed.data,
        },
        isSummaryAvailable: true,
      };
    }
    return {
      ...shared,
      summary: {
        type: 'SOAP',
        data: {
          SUBJECTIVE: null,
          OBJECTIVE: null,
          ASSESSMENT: null,
          CLIENT_RECAP: null,
        },
      },
      isSummaryAvailable: false,
    };
  });

  return { summaries };
};
