import clsx from 'clsx';
import { X } from 'lucide-react';
import React from 'react';
import { tv } from 'tailwind-variants';

import { Badge, HStack, P, cn } from '@eluve/components';

const styles = tv({
  slots: {
    container: 'gap-2 rounded-md px-3 py-2 md:gap-4',
    badge: 'text-title4 flex-shrink-0 text-nowrap rounded-md px-2 py-1',
    content: 'text-title3 min-w-0 flex-1',
    button:
      'hover:bg-surface2 flex-shrink-0 cursor-pointer rounded-full p-1 transition-colors',
  },
  variants: {
    clickable: {
      true: {
        container: 'cursor-pointer',
      },
    },
    type: {
      CPT: {
        badge: 'bg-brandTeal text-brandTealContrast',
      },
      ICD_10: {
        badge: 'bg-brandPurple text-brandPurpleContrast',
      },
      ICD_9: {
        badge: 'bg-brandOrange text-brandOrangeConstrast',
      },
      SNOMED: {},
    },
    status: {
      accepted: {
        container: 'border-borderPrimary border',
        content: 'text-content2',
      },
      recommendation: {
        container: ['bg-dashed-border-recommendation'],
        content: 'text-content4',
      },
    },
  },
  defaultVariants: {},
});

export interface MedicalCodePropsOld {
  code: string;
  type: string;
}

export const MedicalCodeOld: React.FC<MedicalCodePropsOld> = ({
  code,
  type,
}) => {
  const typeLabel = type === 'ICD_10' ? 'ICD-10' : type;

  return (
    <>
      <Badge
        className={cn(
          'pointer-events-none',
          clsx({
            'bg-cyan-500': type === 'CPT',
            'bg-primary': typeLabel === 'ICD-10',
            'bg-gray-10': typeLabel !== 'CPT' && typeLabel !== 'ICD-10',
          }),
        )}
      >
        {type}
      </Badge>
      <P>{code}</P>
    </>
  );
};

type MedicalCodeProps = {
  codeType: 'ICD_10' | 'ICD_9' | 'CPT' | 'SNOMED';
  code: string;
  description: string;
  isRecommended?: boolean;
  onClick?: () => void;
  onClickDismiss?: () => void;
};

export const MedicalCode = React.forwardRef<HTMLDivElement, MedicalCodeProps>(
  (
    {
      codeType,
      code,
      description,
      isRecommended = false,
      onClick,
      onClickDismiss,
    },
    ref,
  ) => {
    const { badge, container, content, button } = styles({
      type: codeType,
      status: isRecommended ? 'recommendation' : 'accepted',
      clickable: Boolean(onClick),
    });

    return (
      <HStack className={container()} wFull={false} inline ref={ref}>
        <div className={badge()} onClick={onClick}>
          {codeType.replace('_', '-')}
        </div>
        <div className={content()} onClick={onClick}>
          <p className="">{`${code} - ${description}`}</p>
        </div>
        {!isRecommended && (
          <div className={button()} onClick={onClickDismiss}>
            <X className="size-3" />
          </div>
        )}
      </HStack>
    );
  },
);

MedicalCode.displayName = 'MedicalCode';
