import { useSuspenseQuery } from '@apollo/client';
import React, { useMemo } from 'react';

import { TooltipLabel } from '@eluve/blocks';
import { ColDefBuilder, DataTable, EluveLogo } from '@eluve/components';
import { VendorTypesEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import { getVendorProvider } from '@eluve/vendor';

const getTenantOutputTemplatesQuery = graphql(`
  query getTenantOutputTemplates {
    externalEhr {
      __typename
      id
      vendor
    }
    llmOutputTemplates {
      __typename
      id
      name
      description
      tenantId
      createdAt
      external_chart_templates {
        __typename
        id
        externalEhrId
      }
    }
  }
`);

type OutputTemplateRow = {
  id: string;
  name: string;
  description: string | null;
  createdAt: string;
  source: VendorTypesEnum | 'Eluve';
};

const SourceCell: React.FC<{ source: VendorTypesEnum | 'Eluve' }> = ({
  source,
}) => {
  if (source === 'Eluve') {
    return <EluveLogo />;
  }

  const vendorProvider = getVendorProvider(source);
  const logoData = vendorProvider.getLogo();
  return <img className="w-10" src={logoData} alt={`${source} logo`} />;
};

const columns = new ColDefBuilder<OutputTemplateRow>()
  .linkSortable('name', (row) => `./${row.id}`)
  .colDef({
    header: 'Source',
    cell: ({
      row: {
        original: { source },
      },
    }) => {
      return (
        <TooltipLabel label={source}>
          <div>
            <SourceCell source={source} />
          </div>
        </TooltipLabel>
      );
    },
  })
  .defaultSortable('description')
  .dateSortable('createdAt', 'Created At')
  .build();

export interface TenantOutputTemplatesListProps {}

export const TenantOutputTemplatesList: React.FC<
  TenantOutputTemplatesListProps
> = () => {
  const { data } = useSuspenseQuery(getTenantOutputTemplatesQuery);

  const rows = useMemo(() => {
    const { externalEhr, llmOutputTemplates } = data;
    return llmOutputTemplates.map<OutputTemplateRow>((template) => ({
      id: template.id,
      name: template.name,
      description: template.description,
      createdAt: template.createdAt,
      source:
        externalEhr?.find(
          (e) => e.id === template.external_chart_templates[0]?.externalEhrId,
        )?.vendor ?? 'Eluve',
    }));
  }, [data]);

  return (
    <DataTable
      columns={columns}
      data={rows}
      enableGlobalSearch
      initialSortingState={[
        {
          id: 'createdAt',
          desc: true,
        },
      ]}
    />
  );
};
