import * as Sentry from '@sentry/react';
import { Loader2, SparklesIcon } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

import { useApiClient } from '@eluve/api-client-provider';
import { TooltipLabel } from '@eluve/blocks';
import { Box, Select, SelectContent, SelectTrigger } from '@eluve/components';
import {
  PromptTemplateSelectContent,
  PromptTemplateSelectItem,
  usePromptTemplates,
} from '@eluve/frontend-feature-user-settings';
import { isLlmOutputDynamicOutput } from '@eluve/graphql-types';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { FeatureFlaggedComponent } from './FeatureFlagged';
import { useAppointmentPromptTemplate } from './useAppointmentPromptTemplate';

interface TemplateSelectorWithAutoSelectOptionProps {
  appointmentId: string;
}

export const TemplateSelectorWithAutoSelectOption: React.FC<
  TemplateSelectorWithAutoSelectOptionProps
> = ({ appointmentId }) => {
  const apiClient = useApiClient();
  const [autoSelecting, setAutoSelecting] = useState(false);
  const tenantId = useTenantIdFromParams();

  const {
    selectedPromptTemplate,
    updateAppointmentPromptTemplate,
    updateAppointmentOutputTemplate,
    selectedOutputTemplate,
  } = useAppointmentPromptTemplate();

  const {
    activeOutputTemplates,
    defaultOutputTemplateId,
    activeSOAPPromptTemplates,
  } = usePromptTemplates();

  const handleAutoSelect = async () => {
    setAutoSelecting(true);
    try {
      const data = await apiClient.llm.recommendTemplate({
        params: {
          tenantId: tenantId!,
          appointmentId,
        },
        body: {},
      });

      if (data.status === 200) {
        updateAppointmentPromptTemplate(data.body.id);
        toast.success(`"${data.body.name}" template has been auto selected.`);
        return;
      }
      toast.error('Failed to auto select template.');
    } catch (e) {
      Sentry.captureException(e);
      toast.error('Failed to auto select template.');
    } finally {
      setAutoSelecting(false);
    }
  };

  const isDynamicOutput = isLlmOutputDynamicOutput(
    selectedPromptTemplate?.outputType,
  );

  const handlePromptTemplateSelect = async (templateId: string) => {
    if (templateId === 'recommended') {
      handleAutoSelect();
      return;
    }

    const newPromptTemplate = activeSOAPPromptTemplates.find(
      (t) => t.id === templateId,
    );
    await updateAppointmentPromptTemplate(templateId);
    if (!isLlmOutputDynamicOutput(newPromptTemplate?.outputType)) {
      await updateAppointmentOutputTemplate(null);
    } else {
      if (!selectedOutputTemplate && defaultOutputTemplateId) {
        await updateAppointmentOutputTemplate(defaultOutputTemplateId);
      }
    }
  };

  const handleOutputTemplateSelect = (templateId: string) => {
    updateAppointmentOutputTemplate(templateId);
  };

  return (
    <Box vStack>
      <Select
        key={'prompt'}
        value={selectedPromptTemplate?.id ?? ''}
        onValueChange={handlePromptTemplateSelect}
      >
        {autoSelecting ? (
          <div className="text-brand-8 flex items-center">
            Auto selecting..{' '}
            <Loader2 className="text-brand-8 h-4 animate-spin" />
          </div>
        ) : (
          <>
            <SelectTrigger className="h-auto w-auto border-0 border-none bg-transparent p-0 focus:border-transparent focus:ring-0 focus:ring-transparent">
              <span className=" text-brand-8 underline underline-offset-4 hover:cursor-pointer">
                {selectedPromptTemplate?.name}
              </span>{' '}
            </SelectTrigger>
            <PromptTemplateSelectContent
              additionalOptions={
                <FeatureFlaggedComponent flag="AUTO_RECOMMEND_PROMPT_TEMPLATE">
                  <hr className="border-gray-3 my-1" />
                  <PromptTemplateSelectItem
                    templateId="recommended"
                    icon={
                      <TooltipLabel label="Powered by AI">
                        <SparklesIcon className="text-primary -ml-2 mr-2 h-6 w-6" />
                      </TooltipLabel>
                    }
                    templateName="Auto select"
                    templateDescription="Based on given transcript auto select the prompt template."
                  />
                </FeatureFlaggedComponent>
              }
            />
          </>
        )}
      </Select>
      {isDynamicOutput && activeOutputTemplates.length > 1 && (
        <Select
          key={'output'}
          value={selectedOutputTemplate?.id ?? ''}
          onValueChange={handleOutputTemplateSelect}
        >
          <SelectTrigger className="h-auto w-auto border-0 border-none bg-transparent p-0 focus:border-transparent focus:ring-0 focus:ring-transparent">
            <span className=" text-brand-8 underline underline-offset-4 hover:cursor-pointer">
              {selectedOutputTemplate?.name}
            </span>{' '}
          </SelectTrigger>

          <SelectContent className="max-h-[50vh] overflow-y-auto">
            {activeOutputTemplates?.map((outputTemplate) => (
              <PromptTemplateSelectItem
                key={outputTemplate.id}
                templateId={outputTemplate.id ?? ''}
                templateName={outputTemplate.name ?? ''}
                templateDescription={''}
              />
            ))}
          </SelectContent>
        </Select>
      )}
    </Box>
  );
};
