import { Undo2 } from 'lucide-react';
import { toast } from 'sonner';

import { useApiClient } from '@eluve/api-client-provider';
import { OneTimeTooltip } from '@eluve/blocks';
import { Button } from '@eluve/components';
import {
  useAllSummaries,
  useAppointmentId,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { useTenantIdFromParams } from '@eluve/session-helpers';

export const RollbackPreviousSummaryButton = () => {
  const allSummaries = useAllSummaries();
  const apiClient = useApiClient();
  const currentSummary = useSummary();
  const appointmentId = useAppointmentId();
  const tenantId = useTenantIdFromParams() ?? '';

  const summaries = allSummaries?.summaries ?? [];
  if (summaries.length < 2) {
    return null;
  }
  const currentLlmOutputId = currentSummary?.llmOutputId;
  const currentIndex = summaries.findIndex(
    (summary) => summary.summaryId === currentLlmOutputId,
  );

  const previousSummary = currentIndex > 0 ? summaries[currentIndex - 1] : null;

  if (!previousSummary) {
    return null;
  }

  return (
    <OneTimeTooltip
      title="Undo Changes"
      position="right"
      scrollContainerQuerySelector="#SummaryTabContent"
      content="You can still go back to the previous version by tapping on the undo button."
    >
      <Button
        id="RollbackPreviousSummaryButton"
        variant="outlinesecondary"
        onClick={async () => {
          const newLlmOutputIdResponse =
            await apiClient.humanOutputs.setPreviousHumanOutputAsDefault({
              params: {
                tenantId,
                appointmentId,
                humanOutputId: currentSummary!.humanOutputId!,
              },
            });
          if (newLlmOutputIdResponse.status !== 200) {
            toast.error('Failed to rollback to previous summary');
          }
          currentSummary.refetch();
        }}
        size={'xs'}
      >
        <Undo2 className="mr-2" size={16} />
        Undo
      </Button>
    </OneTimeTooltip>
  );
};
