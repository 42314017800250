import { useMutation, useSuspenseQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import {
  DynamicArtifactTemplateBuilder,
  templateBuilderStore,
} from '@eluve/blocks';
import { Button, PageTitle } from '@eluve/components';
import {
  DynamicArtifactTemplate,
  dynamicArtifactTemplateSchema,
} from '@eluve/llm-outputs';

import {
  createOutputTemplateMutation,
  getOutputTemplateQuery,
} from './operations';

export const CloneOutputTemplatePage: React.FC = () => {
  const { outputTemplateId } = useParams() as { outputTemplateId: string };
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(false);
  const [createOutputTemplate] = useMutation(createOutputTemplateMutation, {
    onCompleted: ({ insertLlmOutputTemplatesOne: template }) => {
      toast.success('Output template created');
      templateBuilderStore.send({ type: 'reset' });
      if (template) {
        navigate(`/admin/output-templates/${template.id}`);
      }
    },
    onError: () => toast.error('Failed to create output template'),
  });

  const handleSubmit = () => {
    const { context } = templateBuilderStore.getSnapshot();
    const dynamicArtifact: DynamicArtifactTemplate = {
      name: context.name,
      description: context.description,
      blocks: context.blocks,
    };

    createOutputTemplate({
      variables: {
        input: {
          name: context.name,
          description: context.description,
          llmOutputType: context.outputType,
          dynamicArtifactTemplate: dynamicArtifact,
        },
      },
    });
  };

  const {
    data: { llmOutputTemplatesByPk: template },
  } = useSuspenseQuery(getOutputTemplateQuery, {
    variables: {
      id: outputTemplateId,
    },
  });

  const artifactTemplate = useMemo(() => {
    const parsed = dynamicArtifactTemplateSchema.safeParse(
      template?.dynamicArtifactTemplate,
    );
    if (!parsed.success) {
      return undefined;
    }

    return parsed.data;
  }, [template?.dynamicArtifactTemplate]);

  useEffect(() => {
    if (artifactTemplate) {
      templateBuilderStore.send({
        type: 'hydrateArtifact',
        artifactTemplate,
        outputType: template?.llmOutputType ?? 'SOAP_NOTE',
      });
    }
  }, [artifactTemplate, template?.llmOutputType]);

  return (
    <div className="container">
      <PageTitle subtitle={`Cloning from ${template?.name}`}>
        Create Output Template
      </PageTitle>
      <Button disabled={!isValid} onClick={handleSubmit}>
        Submit
      </Button>
      <DynamicArtifactTemplateBuilder onIsValidChange={setIsValid} />
    </div>
  );
};
