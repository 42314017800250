import {
  Editor,
  Extension,
  Node,
  NodeViewProps,
  mergeAttributes,
} from '@tiptap/core';
import { PluginKey } from '@tiptap/pm/state';
import {
  NodeViewWrapper,
  ReactNodeViewRenderer,
  ReactRenderer,
} from '@tiptap/react';
import { SuggestionOptions } from '@tiptap/suggestion';
import Suggestion from '@tiptap/suggestion';
import { Command as CommandPrimitive } from 'cmdk';
import noop from 'lodash/noop';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import tippy, { Instance } from 'tippy.js';

import { MedicalCodeOld } from '@eluve/blocks';
import {
  Box,
  Command,
  CommandInput,
  CommandItem,
  CommandList,
  P,
} from '@eluve/components';

const BILLING_CODES_EXTENSION_NAME = 'billing-codes-extension';
const BILLING_CODE_TAG_NAME = 'billing-code';
const BILLING_CODE_NODE_NAME = 'billing-code-node';

type CommandRef = React.ElementRef<typeof CommandPrimitive>;

type BillingCodesNodeOptions = Partial<SuggestionOptions<SearchableCode>> & {
  onCodeRemoved?: (code: SearchableCode) => void;
  onCodeAdded?: (code: SearchableCode) => void;
  searchCodes?: (query: string) => Promise<SearchableCode[]>;
};

export interface BillingCodeExtensionOptions {
  codeOptions: BillingCodesNodeOptions;
}

export type SearchableCode = {
  id: string;
  title: string;
  type: string;
  description: string;
};

type RendererProps = {
  onCodeAdded: (item: SearchableCode) => Promise<void>;
  searchCodes: (query: string) => Promise<SearchableCode[]>;
  onEscapePressed?: () => void;
};

// TODO(jesse)[ELU-1425]: Refactor this into a shared lib so it can be
// used in more more places
const CodeItemsRenderer = React.forwardRef<HTMLInputElement, RendererProps>(
  (props, ref) => {
    const { onCodeAdded, searchCodes, onEscapePressed } = props;

    const [searchQuery, setSearchQuery] = useState('');
    const [codeResults, setCodeResults] = useState<SearchableCode[]>();

    useDebounce(
      function searchCodesOnQueryChange() {
        const runSearch = async () => {
          const results = await searchCodes(searchQuery);
          setCodeResults(results);
        };

        runSearch();
      },
      500,
      [searchCodes, searchQuery, setCodeResults],
    );

    return (
      <div>
        <Command
          className="rounded-lg border p-2 shadow-md"
          ref={ref}
          ignoreEventPropagation={true}
          shouldFilter={false}
        >
          <CommandInput
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                onEscapePressed?.();
              }
            }}
            onValueChange={(val) => {
              setSearchQuery(val);
            }}
            placeholder="Search codes..."
            ref={(input) => {
              setTimeout(() => {
                input?.focus();
              }, 1);
            }}
          />
          <CommandList>
            {(codeResults ?? []).map((code) => (
              <CommandItem
                onSelect={() => {
                  onCodeAdded(code);
                }}
                key={code.title}
              >
                <Box vStack className="gap-1">
                  <Box hStack>
                    <MedicalCodeOld code={code.title} type={code.type} />
                  </Box>
                  <P className="text-xs font-light italic">
                    {code.description}
                  </P>
                </Box>
              </CommandItem>
            ))}
          </CommandList>
        </Command>
      </div>
    );
  },
);

const BillingCodeNodeView: React.FC<NodeViewProps> = (props) => {
  const { code, codeType } = props.node.attrs;
  const extOptions = props.extension.options as BillingCodesNodeOptions;

  const onCodeRemoved = extOptions?.onCodeRemoved;

  useEffect(() => {
    return () => {
      if (onCodeRemoved) {
        onCodeRemoved(code);
      }
    };
  }, [onCodeRemoved, code]);

  return (
    <NodeViewWrapper as="span">
      <span className="m-0 inline-flex w-fit items-center gap-2 rounded-sm border p-1 shadow-md [&>*]:m-0">
        <MedicalCodeOld code={code} type={codeType} />
      </span>
    </NodeViewWrapper>
  );
};

const getBillingCodesExtensionOptions = (editor: Editor) => {
  const extension = editor.extensionManager.extensions.find(
    (ext) => ext.name === BILLING_CODES_EXTENSION_NAME,
  );
  const options = (extension?.options ?? {}) as BillingCodeExtensionOptions;
  return options;
};

export const BillingCodesNode = Node.create<BillingCodesNodeOptions>({
  name: BILLING_CODE_NODE_NAME,
  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,
  addAttributes() {
    return {
      code: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-code'),
        renderHTML: (attributes) => {
          if (!attributes.code) {
            return {};
          }
          return {
            'data-code': attributes.code,
          };
        },
      },
      codeType: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-code-type'),
        renderHTML: (attributes) => {
          if (!attributes.codeType) {
            return {};
          }
          return {
            'data-code-type': attributes.codeType,
          };
        },
      },
      description: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-description'),
        renderHTML: (attributes) => {
          if (!attributes.description) {
            return {};
          }

          return {
            'data-description': attributes.description,
          };
        },
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: BILLING_CODE_TAG_NAME,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      BILLING_CODE_TAG_NAME,
      mergeAttributes(HTMLAttributes),
      node.attrs.code,
    ];
  },

  addOptions() {
    return {
      char: '#',
      command: ({ editor, range, props }) => {
        editor
          .chain()
          .focus()
          .insertContentAt(range, [
            {
              type: BILLING_CODE_NODE_NAME,
              attrs: {
                code: props.title,
                codeType: props.type,
                description: props.description,
              },
            },
          ])
          .run();
      },
      render: () => {
        let component: ReactRenderer<CommandRef> | null = null;
        let popup: Instance[] | null = null;
        const onEscapePressed = (editor: Editor) => () => {
          popup?.[0]?.hide();
          editor.view.dom.focus();
        };

        return {
          onStart(props) {
            const { command, editor } = props;

            const extOptions = getBillingCodesExtensionOptions(editor);

            const { searchCodes = async () => [], onCodeAdded = noop } =
              extOptions.codeOptions;

            const codeAdded = async (code: SearchableCode) => {
              command(code);
              await onCodeAdded(code);
            };

            const rendererProps: RendererProps = {
              onCodeAdded: codeAdded,
              searchCodes,
              onEscapePressed: onEscapePressed(editor),
            };

            component = new ReactRenderer(CodeItemsRenderer, {
              props: rendererProps,
              editor: props.editor,
            });

            const rect = () => {
              const rect = props.clientRect?.();
              return rect!;
            };

            popup = tippy('body', {
              getReferenceClientRect: rect,
              appendTo: () => document.body,
              content: component.element,
              showOnCreate: true,
              interactive: true,
              trigger: 'manual',
              placement: 'bottom-start',
            });
          },
          onKeyDown(props) {
            if (props.event.key === 'Escape') {
              popup?.[0]?.hide();

              return true;
            }

            return component?.ref?.onkeydown?.(props.event);
          },
          onUpdate(props) {
            const { command, editor } = props;

            const extOptions = getBillingCodesExtensionOptions(editor);

            const { searchCodes = async () => [], onCodeAdded = noop } =
              extOptions.codeOptions;

            const codeAdded = async (code: SearchableCode) => {
              command(code);
              await onCodeAdded(code);
            };

            const rendererProps: RendererProps = {
              onCodeAdded: codeAdded,
              searchCodes,
              onEscapePressed: onEscapePressed(editor),
            };

            component?.updateProps(rendererProps);
          },
          onExit: () => {
            popup?.[0]?.destroy();
            component?.destroy();
          },
        };
      },
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(BillingCodeNodeView, {
      contentDOMElementTag: 'span',
      as: 'span',
    });
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        pluginKey: new PluginKey(BILLING_CODE_NODE_NAME),
        editor: this.editor,
        ...this.options,
      }),
    ];
  },
});

export const BillingCodeExtension =
  Extension.create<BillingCodeExtensionOptions>({
    name: BILLING_CODES_EXTENSION_NAME,

    addExtensions() {
      return [BillingCodesNode.configure(this.options.codeOptions)];
    },
  });
