import { useMutation, useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import { produce } from 'immer';
import React, { useEffect } from 'react';
import { toast } from 'sonner';
import { v4 } from 'uuid';

import { cacheUtils } from '@eluve/apollo-client';
import { MedicalCode } from '@eluve/blocks';
import { H4, H6, HStack, VStack } from '@eluve/components';
import { FragmentOf, graphql } from '@eluve/graphql.tada';

const MotionVStack = motion(VStack);
const MotionH6 = motion(H6);
const MotionMedicalCode = motion(MedicalCode);

export const medicalCodeFragment = graphql(`
  fragment MedicalCode on MedicalCodes @_unmask {
    __typename
    id
    code
    codeType
    description
  }
`);

type MedicalCodeFragment = FragmentOf<typeof medicalCodeFragment>;

const appointmentBillingCodeRecommendationsFragment = graphql(
  `
    fragment BillingCodeRecommedations on Appointments @_unmask {
      __typename
      id
      billing_code_recommendations {
        __typename
        codeId
        appointmentId
        isFinal
        medical_code {
          ...MedicalCode
        }
      }
    }
  `,
  [medicalCodeFragment],
);

const appointmentBillingCodesFragment = graphql(
  `
    fragment BillingCodes on Appointments @_unmask {
      __typename
      id
      billingCodes {
        __typename
        id
        medical_code {
          ...MedicalCode
        }
      }
    }
  `,
  [medicalCodeFragment],
);

export const acceptBillingCodeRecommendationMutation = graphql(`
  mutation acceptBillingCodeRecommendation(
    $appointmentId: uuid!
    $billingCodeId: uuid!
  ) {
    insertAppointmentBillingCodesOne(
      object: { appointmentId: $appointmentId, billingCodeId: $billingCodeId }
      onConflict: { constraint: uq_appointment_code, updateColumns: [] }
    ) {
      __typename
      id
    }
    updateAppointmentBillingCodeRecommendations(
      where: {
        appointmentId: { _eq: $appointmentId }
        codeId: { _eq: $billingCodeId }
      }
      _set: { isFinal: true }
    ) {
      returning {
        __typename
        appointmentId
        codeId
        isFinal
      }
    }
  }
`);

export const removeBillingCodeMutation = graphql(`
  mutation removeBillingCode($appointmentId: uuid!, $billingCodeId: uuid!) {
    deleteAppointmentBillingCodes(
      where: {
        appointmentId: { _eq: $appointmentId }
        billingCodeId: { _eq: $billingCodeId }
      }
    ) {
      returning {
        __typename
        billingCodeId
      }
    }

    updateAppointmentBillingCodeRecommendations(
      where: {
        appointmentId: { _eq: $appointmentId }
        codeId: { _eq: $billingCodeId }
      }
      _set: { isFinal: false }
    ) {
      returning {
        __typename
        appointmentId
        codeId
        isFinal
      }
    }
  }
`);

export const getAppointmentBillingCodes = graphql(
  `
    query getAppointmentBillingCodes($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        ...BillingCodes
        ...BillingCodeRecommedations
      }
    }
  `,
  [
    appointmentBillingCodesFragment,
    appointmentBillingCodeRecommendationsFragment,
  ],
);

export interface AppointmentBillingCodesProps {
  tenantId: string;
  appointmentId: string;
  isReadonly?: boolean;
  shouldPollForResults?: boolean;
}

export const AppointmentBillingCodes: React.FC<
  AppointmentBillingCodesProps
> = ({
  tenantId,
  appointmentId,
  isReadonly = false,
  shouldPollForResults = false,
}) => {
  const { data, loading, startPolling, stopPolling } = useQuery(
    getAppointmentBillingCodes,
    {
      variables: {
        tenantId,
        appointmentId,
      },
    },
  );

  const billingCodes = data?.appointmentsByPk?.billingCodes ?? [];
  const billingCodeRecommendations =
    data?.appointmentsByPk?.billing_code_recommendations?.filter(
      (c) => !c.isFinal,
    ) ?? [];

  useEffect(() => {
    if (shouldPollForResults && !isReadonly) {
      startPolling(2500);
      return () => {
        stopPolling();
      };
    }
  }, [shouldPollForResults, isReadonly, startPolling, stopPolling]);

  useEffect(() => {
    if (billingCodeRecommendations.length) {
      stopPolling();
    }
  }, [billingCodeRecommendations.length, stopPolling]);

  const [acceptRecommendation] = useMutation(
    acceptBillingCodeRecommendationMutation,
    {
      onError: () => toast.error('Failed to accept recommendation'),
      onCompleted: () => toast.success('Recommendation accepted'),
      optimisticResponse: ({ appointmentId, billingCodeId }) => ({
        insertAppointmentBillingCodesOne: {
          __typename: 'AppointmentBillingCodes' as const,
          id: v4(),
        },
        updateAppointmentBillingCodeRecommendations: {
          returning: [
            {
              __typename: 'AppointmentBillingCodeRecommendations' as const,
              appointmentId,
              codeId: billingCodeId,
              isFinal: true,
            },
          ],
        },
      }),
    },
  );

  const [removeBillingCode] = useMutation(removeBillingCodeMutation, {
    onError: () => toast.error('Failed to remove billing code'),
  });

  const onRemoveBillingCode = (codeId: string) => {
    if (isReadonly) {
      return;
    }

    removeBillingCode({
      variables: {
        appointmentId,
        billingCodeId: codeId,
      },
      optimisticResponse: ({ appointmentId, billingCodeId }) => {
        return {
          deleteAppointmentBillingCodes: {
            returning: [
              {
                __typename: 'AppointmentBillingCodes' as const,
                billingCodeId,
              },
            ],
          },
          updateAppointmentBillingCodeRecommendations: {
            returning: [
              {
                __typename: 'AppointmentBillingCodeRecommendations' as const,
                appointmentId,
                codeId: billingCodeId,
                isFinal: false,
              },
            ],
          },
        };
      },
      update: (_cache) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            if (!existing) {
              return existing;
            }
            // Optimistically remove the billing code from the list
            return produce(existing, (draft) => {
              const idx = draft.billingCodes.findIndex(
                (c) => c.medical_code.id === codeId,
              );
              if (idx !== -1) {
                draft.billingCodes.splice(idx, 1);
              }
            });
          },
        );
      },
    });
  };

  const onAcceptRecommendation = ({
    code: { __typename, ...medicalCode },
  }: {
    code: MedicalCodeFragment;
  }) => {
    if (isReadonly) {
      return;
    }
    acceptRecommendation({
      variables: {
        appointmentId,
        billingCodeId: medicalCode.id,
      },
      update: (_cache) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            if (!existing) {
              return existing;
            }

            // Optimistically add the billing code to the list of accepted billing codes
            return produce(existing, (draft) => {
              draft.billingCodes.push({
                __typename: 'AppointmentBillingCodes',
                id: v4(),
                medical_code: {
                  __typename: 'MedicalCodes',
                  ...medicalCode,
                },
              });
            });
          },
        );
      },
    });
  };

  if (loading) {
    return null;
  }

  if (isReadonly && billingCodes.length === 0) {
    return null;
  }

  return (
    <VStack gap={4}>
      <HStack gap={3}>
        <H4>Billing Codes</H4>
        {/* TODO(jesse)[ELU-1425] implement add button */}
        {/* <Button variant="lightgray" size="xs">
          <PlusCircle className="size-3" />
          Add
        </Button> */}
      </HStack>
      <MotionVStack layout gap={6}>
        {Boolean(billingCodes.length) && (
          <HStack gap={3} wrap>
            {billingCodes.map(
              ({
                id,
                medical_code: { id: codeId, code, codeType, description },
              }) => (
                <MotionMedicalCode
                  layout
                  layoutId={codeId}
                  key={id}
                  code={code}
                  codeType={codeType}
                  description={description ?? ''}
                  onClickDismiss={() => onRemoveBillingCode(codeId)}
                />
              ),
            )}
          </HStack>
        )}
        {Boolean(billingCodeRecommendations.length && !isReadonly) && (
          <VStack gap={3}>
            <MotionH6 layout>Recommended Codes</MotionH6>
            <HStack gap={3} wrap>
              {billingCodeRecommendations.map(({ medical_code }) => {
                const {
                  id: codeId,
                  code,
                  codeType,
                  description,
                } = medical_code!;
                return (
                  <MotionMedicalCode
                    layout
                    layoutId={codeId}
                    onClick={() =>
                      onAcceptRecommendation({
                        code: medical_code!,
                      })
                    }
                    isRecommended
                    key={codeId}
                    code={code}
                    codeType={codeType}
                    description={description ?? ''}
                  />
                );
              })}
            </HStack>
          </VStack>
        )}
      </MotionVStack>
    </VStack>
  );
};
